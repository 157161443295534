<template>
  <div class="font-principal">
    <div class="container">
      <section class="section-bike-img" style="margin: 0;">
        <div class="text-center">
          <div class="mt-5" :class="step > 5 ? 'mb-4' : ''">
            <b-img width="190px" src="./img/logosBIke-02.png"></b-img>
          </div>
        </div>
      </section>
      <!-- Tela 1 - Introdução timeline-->
      <section v-if="step >= 1 && step < 5">
        <div class="section-steps">
          <div class="stepper-wrapper wap">
            <div class="stepper-item" :class="step == 1 ? 'completed' : 'active'">
              <div class="step-counter text-white"></div>
            </div>
            <div class="stepper-item" :class="step == 2 ? 'completed' : 'active'">
              <div class="step-counter text-white"></div>
              <div class="step-name"></div>
            </div>
            <div class="stepper-item" :class="step == 3 ? 'completed' : 'active'">
              <div class="step-counter text-white"></div>
              <div class="step-name"></div>
            </div>
            <div class="stepper-item" :class="step == 4 ? 'completed' : 'active'">
              <div class="step-counter text-white"></div>
              <div class="step-name"></div>
            </div>
          </div>
        </div>
      </section>
      <!-- Tela 1 - Introdução -->

      <section v-show="step === 0" class="mt-5 mb-5">
        <div class="step9">
          <div>
            <div class="texto-principal ">
              <div class="col-12" style="font-size:26px">
                <span class="padrao"> {{ animatedText0 }} </span>
                <span class="padrao-destaque"> {{ animatedText0Sub }}</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <transition name="slide-fade">
        <section v-if="step === 1" class="step9">
          <div class="" style="width: 549px;">
            <div>
              <div class="texto-principal">
                <div v-if="showText" class="padrao text-left mb-4">
                  {{ animatedText1 }}
                </div>
              </div>

              <ValidationObserver ref="formObserver">
                <form data-test-id="form-novo-cliente">
                  <div class="form-group mb-5">
                    <ValidationProvider name="nome" rules="required|nome_completo" mode="passive"
                      v-slot="{ errors, classes }">
                      <input type="text" class="form-control1" :style="nome
                        ? 'color: #454545;background:#fff;'
                        : 'color: #9A9A9A;'
                        " :class="classes" v-model="nome" @focus="clearErrors" maxlength="100" autocomplete="nope"
                        placeholder="Insira seu nome completo" />
                      <span class="ml-3 invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="text-center">
                    <button class="bike-btn" data-test-id="btn-continuar" :disabled="btn" @click.prevent="formObserver">
                      <span class="text">CONTINUAR</span>
                      <b-img class="img-button" src="./img/arrow-button.svg"></b-img>
                    </button>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </section>
      </transition>

      <transition name="slide-fade">
        <section v-if="step === 2" class="step9">
          <div class="" style="width: 549px;">
            <div class="texto-principal">
              <div class="link-bike" @click.prevent="voltarPasso()">
                Voltar
              </div>
              <div id="text" class="padrao text-left mb-4">
                <span v-if="showText" class="padrao-destaque">{{
                  helper.quebrarNome(nome)[0]
                }}</span>, {{ animatedText2 }}
              </div>
            </div>
            <ValidationObserver ref="formObserver1">
              <form data-test-id="form-novo-cliente">
                <div class="form-group mb-5">
                  <ValidationProvider mode="passive" name="nascimento" rules="required|data_nascimento|data_maior_idade"
                    v-slot="{ errors, classes }">
                    <input :style="nascimento ? 'color: #454545;background:#fff;' : ''
                      " type="tel" class="form-control1" :class="classes" v-model="nascimento" @focus="clearErrors1"
                      v-mask="'##/##/####'" placeholder="Insira sua data de nascimento" autocomplete="nope" />
                    <span class="ml-3 invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="text-center">
                  <button class="bike-btn" data-test-id="btn-continuar" :disabled="btn" @click.prevent="formObserver1">
                    <span v-if="!loading" class="text">CONTINUAR</span>
                    <span v-else class="text">AGUARDE</span>
                    <b-img class="img-button" src="./img/arrow-button.svg"></b-img>
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </section>
      </transition>

      <transition name="slide-fade">
        <section v-show="step === 3" class="step9">
          <div class="" style="width: 549px;">
            <div class="texto-principal">
              <div class="padrao text-left mb-4">
                <div class="link-bike" @click.prevent="voltarPasso()">
                  Voltar
                </div>
                <span class="padrao-destaque">{{
                  helper.quebrarNome(nome)[0]
                }}</span>, {{ animatedText3 }}
                <div class="sub mt-3">
                  {{ animatedText3Sub }}
                </div>
              </div>
            </div>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit()" data-test-id="form-novo-cliente">
                <div class="form-group mb-5">
                  <ValidationProvider name="telefone" rules="required|min:15" v-slot="{ errors, classes }">
                    <input :style="telefone ? 'color: #454545; background:#fff;' : ''
                      " type="tel" class="form-control1" :class="classes" v-model="telefone"
                      v-mask="['(##) ####-####', '(##) #####-####']" placeholder="Insira o seu telefone com DDD"
                      autocomplete="nope" />
                    <span class="text-left invalid-feedback">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </div>
                <div class="row d-flex justify-content-center">
                  <button class="bike-btn" :disabled="btn" data-test-id="btn-continuar" @click.prevent="callback">
                    <span v-if="!loading" class="text">CONTINUAR</span>
                    <span v-else class="text">AGUARDE</span>
                    <b-img class="img-button" src="./img/arrow-button.svg"></b-img>
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </section>
      </transition>

      <transition name="slide-fade">
        <section v-show="step === 4" class="step9">
          <div class="" style="width: 549px;">
            <div class="texto-principal">
              <div class="padrao text-left mb-4">
                <div class="link-bike" @click.prevent="voltarPasso()">
                  Voltar
                </div>
                <span class="padrao-destaque">{{
                  helper.quebrarNome(nome)[0]
                }}</span>, {{ animatedText4 }}
                <div class="sub mt-3">
                  {{ animatedText4Sub }}
                </div>
              </div>
            </div>

            <ValidationObserver ref="formObserver3">
              <form data-test-id="form-novo-cliente">
                <div class="form-group mb-5">
                  <ValidationProvider name="email" mode="passive" rules="required|email" v-slot="{ errors, classes }">
                    <input :style="email ? 'color: #454545;background:#fff;' : ''" type="text" class="form-control1"
                      :class="classes" v-model="email" @focus="clearErrors3" maxlength="100" data-test-id="input-email"
                      placeholder="Insira o seu email" autocomplete="nope" />
                    <span class="ml-3 invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="text-center">
                  <button class="bike-btn" :disabled="btn" data-test-id="btn-continuar" @click.prevent="formObserver3">
                    <span v-if="!loading" class="text">CONTINUAR</span>
                    <span v-else class="text">AGUARDE</span>
                    <b-img class="img-button" src="./img/arrow-button.svg"></b-img>
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </section>
      </transition>

      <transition name="slide-fade">
        <section v-show="step === 17" class="step9">
          <div class="" style="width: 549px;">
            <div class="texto-principal">
              <div class="padrao text-left mb-4">
                <div class="link-bike" @click.prevent="voltarPassoCPF()">
                  Voltar
                </div>
                <span class="padrao-destaque">{{
                  helper.quebrarNome(nome)[0]
                }}</span>, {{ animatedText17 }}
                <div class="sub mt-3">
                  {{ animatedText17Sub }}
                </div>
              </div>
            </div>

            <ValidationObserver ref="formObserver2">
              <form data-test-id="form-novo-cliente">
                <div class="form-group mb-5">
                  <ValidationProvider mode="passive" name="CPF" rules="required|cpf" v-slot="{ errors, classes }">
                    <input :style="cpf ? 'color: #454545;background:#fff;' : ''" type="tel" class="form-control1"
                      :class="classes" v-model="cpf" @focus="clearErrors2" v-mask="'###.###.###-##'" maxlength="20"
                      autocomplete="nope" placeholder="Digite o seu CPF" />
                    <span class="ml-3 invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="text-center">
                  <button class="bike-btn" :disabled="btn" data-test-id="btn-continuar" @click.prevent="formObserver2">
                    <span v-if="!loading" class="text">CONTINUAR</span>
                    <span v-else class="text">AGUARDE</span>
                    <b-img class="img-button" src="./img/arrow-button.svg"></b-img>
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </section>
      </transition>

      <transition name="slide-fade">
        <!-- Tela 2 - Checkout -->
        <section v-show="step === 5" class="step9 ">
          <div class="" style="width: 549px;">
            <div class="texto-principal">
              <div class="padrao text-left mt-3 mb-4">
                <div class="link-bike" @click.prevent="voltarPassoStep5()">
                  Voltar
                </div>
                <span class="padrao-destaque">{{
                  helper.quebrarNome(nome)[0]
                }}</span>, {{ animatedText5 }}
              </div>
            </div>
            <ValidationObserver ref="formObserver4">
              <form data-test-id="form-novo-cliente">
                <div class="modalidades row">
                  <div class="form-group col-md-12  mb-4">
                    <ValidationProvider name="marca" rules="required" mode="passive" v-slot="{ errors, classes }">
                      <select class="form-control-select" :class="classes" v-model="marcaBike" @focus="clearErrors4"
                        data-test-id="input-modalidade">
                        <option :value="undefined" style="text-align:center">Selecione a marca da bike</option>
                        <option style="text-align:center" v-for="(item, index) in lstMarcas" :key="'modalidade' + index"
                          :value="item">
                          {{ item.nome }}
                        </option>
                      </select>
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group col-md-12 mb-4">
                    <ValidationProvider name="modelo" mode="passive" rules="required" v-slot="{ errors, classes }">
                      <input :style="modeloBike
                        ? 'text-align:center;color: #454545;background:#fff;'
                        : 'text-align:center'
                        " type="text" class="form-control1" v-model="modeloBike" @focus="clearErrors4" :class="classes"
                        placeholder="Digite o modelo da sua bike" maxlength="100" autocomplete="nope" />
                      <span class="text-left invalid-feedback">{{
                        errors[0]
                      }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="form-group col-md-12 mb-4" v-if="ehMozila">
                    <ValidationProvider name="valor da bike" :rules="'required|valor_bike'"
                      v-slot="{ errors, classes }">
                      <money v-model="valorBike" class="form-control1" :style="valorBike > 0
                        ? 'text-align:center;color: #454545;background:#fff;'
                        : 'text-align:center'
                        " v-bind="{
                          decimal: ',',
                          thousands: '.',
                          prefix: 'R$ ',
                          suffix: '',
                          precision: 0,
                        }" :class="['form-control', classes]"></money>
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group col-md-12 mb-4" v-else>
                    <ValidationProvider name="valor" mode="passive" :rules="'required|valor_bike'"
                      v-slot="{ errors, classes }">
                      <input type="tel" class="form-control1" v-mask="getCurrencyMask(valorPlanoString)" :style="valorPlanoString
                        ? 'text-align:center;color: #454545;background:#fff;'
                        : 'text-align:center'
                        " :class="classes" v-model="valorPlanoString" @focus="clearErrors4" maxlength="100"
                        autocomplete="nope" placeholder="Digite o valor de sua bike" />
                      <span class="text-left invalid-feedback">{{
                        errors[0]
                      }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="form-group col-md-12">
                    <ValidationProvider name="estado" mode="passive" rules="required" v-slot="{ errors, classes }">
                      <select class="form-control-select" v-model="estado" @focus="clearErrors4" :class="classes"
                        data-test-id="input-estado">
                        <option :value="undefined">Estado em que sua Bike Reside</option>
                        <option value="AC">Acre</option>
                        <option value="AL">Alagoas</option>
                        <option value="AM">Amazonas</option>
                        <option value="AP">Amapá</option>
                        <option value="BA">Bahia</option>
                        <option value="CE">Ceará</option>
                        <option value="DF">Distrito Federal</option>
                        <option value="ES">Espírito santo</option>
                        <option value="GO">Goiás</option>
                        <option value="MA">Maranhão</option>
                        <option value="MG">Minas Gerais</option>
                        <option value="MS">Mato Grosso do sul</option>
                        <option value="MT">Mato Grosso</option>
                        <option value="PA">Pará</option>
                        <option value="PB">Paraíba</option>
                        <option value="PE">Pernambuco</option>
                        <option value="PI">Piauí</option>
                        <option value="PR">Paraná</option>
                        <option value="RJ">Rio de Janeiro</option>
                        <option value="RN">Rio Grande do Norte</option>
                        <option value="RO">Roraima</option>
                        <option value="RR">Rondônia</option>
                        <option value="RS">Rio Grande do Sul</option>
                        <option value="SC">Santa Catarina</option>
                        <option value="SE">Sergipe</option>
                        <option value="SP">São Paulo</option>
                        <option value="TO">Tocantins</option>
                      </select>
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="text-center col-md-12">
                    <button class="bike-btn" :disabled="btn" data-test-id="btn-continuar"
                      @click.prevent="formObserver4">
                      <span v-if="!loading" class="text">CONTINUAR</span>
                      <span v-else class="text">AGUARDE</span>
                      <b-img class="img-button" src="./img/arrow-button.svg"></b-img>
                    </button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </section>
      </transition>
      <transition name="slide-fade">
        <section v-show="step === 15" class="step9 ">
          <div style="width: 549px;">
            <div class="texto-principal">
              <div class="link-bike" @click.prevent="proximoPassoNF(3)">
                Voltar
              </div>
              <div class="padrao text-left mb-4">
                <span class="padrao-destaque">
                  {{ helper.quebrarNome(nome)[0] }}</span>,{{ animatedText15 }}
                <div class="sub mt-3">
                  {{ animatedText15Sub }}
                </div>
              </div>
            </div>
            <div class="padrao text-left">
              <button class="btn-sim mr-3" :disabled="btn" @click.prevent="proximoPassoNF(1)">
                SIM
              </button>
              <button class="btn-nao" :disabled="btn" @click.prevent="proximoPassoNF(2)">
                NÃO
              </button>
            </div>
          </div>
        </section>
      </transition>
      <transition name="slide-fade">
        <section v-show="step === 6" class="step9">
          <div class="" style="width: 549px;">
            <div>
              <div class="texto-principal padrao text-left mt-3 mb-4">
                <div class="link-bike" @click.prevent="voltarPassoNF()">
                  Voltar
                </div>
                Perfeito
                <span class="padrao-destaque">{{
                  helper.quebrarNome(nome)[0]
                }}</span>, {{ animatedText6 }}
              </div>
              <div class="revisao-cards">
                <div class="text-left mb-1">
                  <span class="padrao-destaque-revisao">Nome Completo:</span>
                  <span class="padrao-revisao"> {{ nome }}</span>
                </div>
                <div class="text-left  mb-1">
                  <span class="padrao-destaque-revisao">Data de Nascimento:</span>
                  <span class="padrao-revisao"> {{ nascimento }}</span>
                </div>
                <div class="text-left mb-1">
                  <span class="padrao-destaque-revisao">Seu melhor email:</span>
                  <span class="padrao-revisao"> {{ email }}</span>
                </div>
                <div class="text-left">
                  <span class="padrao-destaque-revisao">Seu Celular:</span>
                  <span class="padrao-revisao"> {{ telefone }}</span>
                </div>
                <div class="link-card-editar-dados text-right" @click.prevent="voltarPassoStep()">
                  Editar dados
                </div>
              </div>

              <div class="revisao-cards mt-3">
                <div class="text-left mb-1">
                  <span class="padrao-destaque-revisao">Marca da Bike:</span>
                  <span class="padrao-revisao"> {{ nomeMarcaBike }}</span>
                </div>
                <div class="text-left mb-1">
                  <span class="padrao-destaque-revisao">Modelo da Bike:</span>
                  <span class="padrao-revisao"> {{ modeloBike }}</span>
                </div>
                <div class="text-left mb-1">
                  <span class="padrao-destaque-revisao">Valor da Bike:</span>
                  <span class="padrao-revisao">
                    R$ {{ formatacaoMoeda2(valorBike) }}</span>
                </div>
                <div class="text-left">
                  <span class="padrao-destaque-revisao">Estado que a Bike Reside:</span>
                  <span class="padrao-revisao"> {{ ufCotacao }}</span>
                </div>
                <div class="link-card-editar-dados text-right" @click.prevent="voltarPasso()">
                  Editar dados
                </div>
              </div>

              <div class="row">
                <div class="col-12 text-center mb-5">
                  <button class="bike-btn" data-test-id="btn-continuar" :disabled="btn" @click.prevent="proximoPasso()">
                    <span v-if="!loading" class="text">CONTINUAR</span>
                    <span v-else class="text">AGUARDE</span>
                    <b-img class="img-button" src="./img/arrow-button.svg"></b-img>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </transition>
      <transition name="slide-fade">
        <section v-show="step === 14" class="step9">
          <div class="" style="width: 549px;">
            <div>
              <div class="texto-principal padrao text-left mt-3 mb-4">
                <div class="link-bike" @click.prevent="voltarPassoEspecial()">
                  Voltar
                </div>
                Perfeito
                <span class="padrao-destaque">{{
                  helper.quebrarNome(nome)[0]
                }}</span>, {{ animatedText14 }}
                <div class="my-5">{{ animatedText14Sub }}</div>
              </div>
              <div class="row">
                <div class="col-12 text-center mb-5">
                  <a class="btn bike-btn-s" data-test-id="btn-continuar" target="_blank" :href="`https://api.whatsapp.com/send?phone=${config.WHATSAPP_NUMERO
                    }&text=Olá, meu nome é ${auth.getUserName()} e preciso de atendimento. Pois o valor da minha bike é R$ ${formatacaoMoeda2(
                      valorBike
                    )}`
                    ">
                    <span v-if="!loading" class="text">Receber Cotação</span>
                    <span v-else class="text">AGUARDE</span>
                    <b-img class="img-button" src="./img/arrow-button.svg"></b-img>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </transition>
      <transition name="slide-fade">
        <section v-show="step === 7" class="step9 ">
          <div style="width: 549px;">
            <div class="texto-principal">
              <div class="link-bike" @click.prevent="voltarPasso()">
                Voltar
              </div>
              <div class="padrao text-left mb-4">
                <span class="padrao-destaque">
                  {{ helper.quebrarNome(nome)[0] }}</span>,{{ animatedText7 }}
              </div>
            </div>
            <div class="padrao text-left">
              <button class="btn-sim mr-3" :disabled="btn" @click.prevent="proximoPasso()">
                SIM
              </button>
              <button class="btn-nao" :disabled="btn" @click.prevent="proximoPassoDuplo()">
                NÃO
              </button>
            </div>
          </div>
        </section>
      </transition>
      <transition name="slide-fade">
        <section v-show="step === 8" class="step9">
          <div class="" style="width: 628px;">
            <div class="texto-principal">
              <div class="link-bike" @click.prevent="voltarPasso()">
                Voltar
              </div>
              <div class="padrao text-left mb-4">
                <span class="padrao-destaque">
                  {{ helper.quebrarNome(nome)[0] }}</span>, {{ animatedText7 }}
              </div>
            </div>
            <div class="revisao-cards-2 ">
              <div class="row">
                <div class="text-left mb-1 col-sm-12 sub">
                  <strong class="padrao" style="font-size:14px">informação importante</strong>
                </div>
                <div class="text-left mb-3 col-sm-12 col-md-4 sub" style=" align-self: center;">
                  <div class="row">
                    <div class="col-3" style="display: inline-flex;
    align-items: center;">
                      <i class="fas fa-user-circle padrao-destaque" style="font-size:50px; "></i>
                    </div>
                    <div class="col-9 padrao " style="font-size:12px;">
                      <strong>exigencias para cobertura de sinistro para
                        acessórios</strong>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-md-8">
                  <strong class="padrao-2" style="line-height:1px;font-size:16px;">Atenção:</strong>
                  <span class="padrao sub-2" style="line-height:1px;font-size:14px;">Você está incluindo cobertura para
                    um acessório. Em caso de
                    sinistro, será obrigatorio a apresentação da nota fiscal do
                    aparelho com a data anterior a contratação do seguro. Você
                    possui nota fiscal?</span>
                </div>
              </div>
            </div>
            <div class="text-left mt-5 row">
              <div class="col-sm-12 col-md-6">
                <button class="btn-block btn-sim-1 mb-4" @click.prevent="habilitarAcessorio(true)">
                  Sim, possuo nota fiscal
                </button>
              </div>
              <div class="col-sm-12 col-md-6" :class="coberturaAcessorio ? '' : 'mb-5'">
                <button class="btn-block btn-nao-2" @click.prevent="habilitarAcessorio(false)">
                  Não tenho nota fiscal
                </button>
              </div>
            </div>
            <div v-if="coberturaAcessorio" class="mt-4 mb-5 text-center">
              <!-- <div class="link-card text-right" @click.prevent="voltarPasso()">
              Voltar
            </div> -->
              <ValidationObserver ref="formObserver5">
                <form data-test-id="">
                  <div>
                    <div>
                      <div class="modalidades row" v-if="coberturaAcessorio">
                        <div class="form-group col-sm-12 col-md-12" v-if="coberturaAcessorio">
                          <ValidationProvider name="descrição do acessório" rules="required" mode="passive"
                            v-slot="{ errors, classes }">
                            <select class="form-control-select" v-model="nomeAcessorio" @focus="clearErrors5"
                              :class="classes" data-test-id="input-estado">
                              <option :value="undefined">Selecione o acessório</option>
                              <option value="Ciclocomputador/ GPS/ Garmin">Ciclocomputador/ GPS/ Garmin</option>
                              <!-- <option value="Medidor de potência"
                                >Medidor de potência</option
                              >
                              <option value="Sensor de cadência"
                                >Sensor de cadência</option
                              >
                              <option value="Capacete">Capacete</option> -->
                            </select>
                            <span class="invalid-feedback">{{
                              errors[0]
                            }}</span>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-sm-12 col-md-12" v-if="coberturaAcessorio">
                          <ValidationProvider name="marca do acessório" rules="required" mode="passive"
                            v-slot="{ errors, classes }">
                            <input :style="marcaAcessorio
                              ? 'text-align: center;color: #454545;background:#fff;'
                              : 'text-align: center;'
                              " type="text" class="form-control1" v-model="marcaAcessorio" @focus="clearErrors5"
                              :class="classes" placeholder="Digite a marca do acessório" maxlength="100"
                              autocomplete="nope" />
                            <span class="invalid-feedback">{{
                              errors[0]
                            }}</span>
                          </ValidationProvider>
                        </div>

                        <div class="form-group col-sm-12 col-md-12" v-if="coberturaAcessorio">
                          <div v-if="ehMozila">
                            <ValidationProvider name="valor do acessório" :rules="'required|valor_acessorio'"
                              v-slot="{ errors, classes }">
                              <money v-model="valorAcessorio" class="form-control1" :style="valorAcessorio > 0
                                ? 'text-align:center;color: #454545;background:#fff;'
                                : 'text-align:center'
                                " v-bind="{
                                  decimal: ',',
                                  thousands: '.',
                                  prefix: 'R$ ',
                                  suffix: '',
                                  precision: 0,
                                }" :class="['form-control', classes]"></money>
                              <span class="invalid-feedback">{{
                                errors[0]
                              }}</span>
                            </ValidationProvider>
                          </div>
                          <div v-else>
                            <ValidationProvider name="valor do acessório" mode="passive"
                              :rules="'required|valor_acessorio'" v-slot="{ errors, classes }">
                              <input type="tel" class="form-control1" v-mask="getCurrencyMask(valorAcessorioString)"
                                :style="valorAcessorioString
                                  ? 'text-align:center;color: #454545;background:#fff;'
                                  : 'text-align:center'
                                  " :class="classes" v-model="valorAcessorioString" @focus="clearErrors5"
                                maxlength="100" autocomplete="nope" placeholder="Digite o valor do seu acessório" />
                              <span class="invalid-feedback">{{
                                errors[0]
                              }}</span>
                            </ValidationProvider>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-center mt-3">
                    <button class="bike-btn" data-test-id="btn-continuar" :disabled="btn"
                      @click.prevent="formObserver5">
                      <span v-if="!loading" class="text">CONTINUAR</span>
                      <span v-else class="text">AGUARDE</span>
                      <b-img class="img-button" src="./img/arrow-button.svg"></b-img>
                    </button>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </section>
      </transition>
      <transition name="slide-fade">
        <section v-show="step === 9" class="step9 mobile-margin">
          <div>
            <div class="d-block d-md-flex justify-content-center">
              <div class="">
                <div class="texto-principal ">
                  <div class="link-bike" @click.prevent="voltarPasso()">
                    Voltar
                  </div>
                  <div class="padrao text-left mb-4">
                    <span class="padrao-destaque">
                      {{ helper.quebrarNome(nome)[0] }}</span>,{{ animatedText9 }}
                  </div>
                  <div class="card-tipo mb-4">
                    <div @click.prevent="btnPlanoMensal()" :class="planoMensal
                      ? 'card-tipo-plano-ativo'
                      : 'card-tipo-plano'
                      ">
                      <div :style="planoMensal ? 'color:white;' : ''" class="texto-plano-flutuante-2 mt-1">
                        MENSAL
                      </div>
                    </div>
                    <!-- <div @click.prevent="btnPlanoSemestral()" :class="planoSemestral
                      ? 'card-tipo-plano-ativo'
                      : 'card-tipo-plano'
                      ">
                      <div :style="planoSemestral ? 'color:white;' : ''" class="texto-plano-flutuante-2 mt-1">
                        SEMESTRAL
                      </div>
                      <div>
                        <div class="tag">
                          <div class="tag-text">
                            <span class="tag-text-percentual">
                              20% OFF
                            </span>
                          </div>
                        </div>
                      </div>
                    </div> -->
                    <div @click.prevent="btnPlanoAnual()" :class="planoAnual ? 'card-tipo-plano-ativo' : 'card-tipo-plano'
                      ">
                      <div :style="planoAnual ? 'color:white;' : ''" class="texto-plano-flutuante-2 mt-1">
                        ANUAL
                      </div>
                      <div>
                        <div class="tag">
                          <div class="tag-text">
                            <span class="tag-text-percentual">
                              25% OFF
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <div class="card-tipo2">
                    <div v-for="(plano, index) in lstPlanos" :key="'plano' + index">
                      <div v-if="plano.id === config.ID_PLANO_URBANO" @click.prevent="cardSelecionadoUrbano(plano)"
                        class="mb-4" :class="selecionadoUrbano ? 'card-selecionado' : 'card-plano'
                          ">
                        <div :class="selecionadoUrbano
                          ? 'card-plano-titulo-selecionado'
                          : 'card-plano-titulo'
                          ">
                          <span :class="selecionadoUrbano
                            ? 'card-plano-titulo-texto-selecionado'
                            : 'padrao card-plano-titulo-texto'
                            ">PRATA</span>
                        </div>
                        <div class="card-plano-sub-titulo">
                          <span class="card-plano-sub-titulo-nivel">Nível de Cobertura: 3/</span><span
                            class="card-plano-sub-titulo-nivel-2">11</span>
                        </div>
                        <div class="card-plano-nivel">
                          <div :class="selecionadoUrbano
                            ? 'card-plano-nivel-baixo2'
                            : 'card-plano-nivel-baixo'
                            ">
                            Baixo
                          </div>
                          <div class="card-plano-nivel-medio">
                            Médio
                          </div>
                          <div class="card-plano-nivel-alto">
                            Alto
                          </div>
                        </div>
                        <div class="card-plano-nivel mt-2">
                          <div :class="selecionadoUrbano
                            ? 'card-plano-coberturas-selecionado'
                            : 'card-plano-coberturas'
                            " @click.prevent="abrirModalInfor()">
                            Ver Coberturas
                          </div>
                        </div>
                        <div class="card-plano-valor">
                          <div class="card-plano-valor-de">
                            <span v-if="planoAnual">
                              DE:
                              {{
                                formatacaoMoeda2(
                                  plano.valorAnualComAssistencia24hMKT
                                )
                              }}</span>
                            <span v-else-if="planoSemestral">
                              DE:
                              {{
                                formatacaoMoeda2(
                                  plano.valorSemestralComAssistencia24hMKT
                                )
                              }}</span>

                          </div>
                          <div :class="selecionadoUrbano
                            ? 'card-plano-valor-por-selecionado'
                            : 'card-plano-valor-por'
                            ">
                            <span v-if="planoAnual || planoSemestral">
                              POR:</span>
                          </div>
                        </div>
                        <div :class="selecionadoUrbano
                          ? 'card-plano-valor-total-selecionado'
                          : 'card-plano-valor-total'
                          ">
                          <div v-if="planoAnual">
                            <div style="display:flex">
                              <div>
                                {{
                                  formatacaoMoeda(
                                    separarNumero(
                                      plano.valorAnualComAssistencia24h
                                    )
                                  )
                                }}
                              </div>
                              <div class="preco-numero">
                                <div>
                                  ,{{
                                    separarNumeroDecimal(
                                      plano.valorAnualComAssistencia24h
                                    )
                                  }}
                                </div>
                                <div v-if="planoAnual" class="card-principal-planos-tipo padrao">
                                  /ano
                                </div>
                                <div v-else-if="planoSemestral" class="card-principal-planos-tipo padrao">
                                  /sem.
                                </div>
                                <div v-else class="card-principal-planos-tipo padrao">
                                  /mês
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-else-if="planoSemestral">
                            <div style="display:flex">
                              <div :class="selecionadoUrbano
                                ? 'padrao-destaque'
                                : 'padrao'
                                ">
                                {{
                                  formatacaoMoeda(
                                    separarNumero(
                                      plano.valorSemestralComAssistencia24h
                                    )
                                  )
                                }}
                              </div>
                              <div class="preco-numero">
                                <div>
                                  ,{{
                                    separarNumeroDecimal(
                                      plano.valorSemestralComAssistencia24h
                                    )
                                  }}
                                </div>
                                <div v-if="planoAnual" class="card-principal-planos-tipo padrao">
                                  /ano
                                </div>
                                <div v-else-if="planoSemestral" class="card-principal-planos-tipo padrao">
                                  /sem.
                                </div>
                                <div v-else class="card-principal-planos-tipo padrao">
                                  /mês
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-else>
                            <div style="display:flex" class="mt-5">
                              <div>
                                {{
                                  formatacaoMoeda(
                                    separarNumero(
                                      plano.valorMensalComAssistencia24h
                                    )
                                  )
                                }}
                              </div>
                              <div class="preco-numero">
                                <div>
                                  ,{{
                                    separarNumeroDecimal(
                                      plano.valorMensalComAssistencia24h
                                    )
                                  }}
                                </div>
                                <div v-if="planoAnual" class="card-principal-planos-tipo padrao">
                                  /ano
                                </div>
                                <div v-else-if="planoSemestral" class="card-principal-planos-tipo padrao">
                                  /sem.
                                </div>
                                <div v-else class="card-principal-planos-tipo padrao">
                                  /mês
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div :style="planoMensal ? 'margin-top:19px' : ''">
                          <div @click.prevent="cardSelecionadoUrbano()" :class="selecionadoUrbano
                            ? 'card-plano-selecionar-selecionado'
                            : 'card-plano-selecionar'
                            ">
                            <span v-if="selecionadoUrbano">
                              Selecionado
                            </span>
                            <span v-else>
                              Selecionar
                            </span>
                          </div>
                        </div>
                      </div>
                      <div v-if="plano.id === config.ID_PLANO_ATIVO" @click.prevent="cardSelecionadoAtivo(plano)"
                        class="mb-4" :class="selecionadoAtivo ? 'card-selecionado' : 'card-plano'
                          ">
                        <div :class="selecionadoAtivo
                          ? 'card-plano-titulo-selecionado'
                          : 'card-plano-titulo'
                          ">
                          <span :class="selecionadoAtivo
                            ? 'padrao card-plano-titulo-texto-selecionado'
                            : 'padrao card-plano-titulo-texto'
                            ">OURO</span>
                        </div>
                        <div class="card-plano-sub-titulo">
                          <span class="card-plano-sub-titulo-nivel">Nível de Cobertura: 9/</span><span
                            class="card-plano-sub-titulo-nivel-2">11</span>
                        </div>
                        <div class="card-plano-nivel">
                          <div :class="selecionadoAtivo
                            ? 'card-plano-nivel-baixo2'
                            : 'card-plano-nivel-baixo'
                            ">
                            Baixo
                          </div>
                          <div :class="selecionadoAtivo
                            ? 'card-plano-nivel-medio2'
                            : 'card-plano-nivel-medio'
                            ">
                            Médio
                          </div>
                          <div class="card-plano-nivel-alto">
                            Alto
                          </div>
                        </div>
                        <div class="card-plano-nivel mt-2">
                          <div :class="selecionadoAtivo
                            ? 'card-plano-coberturas-selecionado'
                            : 'card-plano-coberturas'
                            " @click.prevent="abrirModalInfor()">
                            Ver Coberturas
                          </div>
                        </div>
                        <div class="card-plano-valor">
                          <div class="card-plano-valor-de">
                            <span v-if="planoAnual">DE:{{
                              formatacaoMoeda2(
                                plano.valorAnualComAssistencia24hMKT
                              )
                            }}</span>
                            <span v-else-if="planoSemestral">DE:{{
                              formatacaoMoeda2(
                                plano.valorSemestralComAssistencia24hMKT
                              )
                            }}</span>

                          </div>
                          <div :class="selecionadoAtivo
                            ? 'card-plano-valor-por-selecionado'
                            : 'card-plano-valor-por'
                            ">
                            <span v-if="planoAnual || planoSemestral">
                              POR:</span>
                          </div>
                        </div>
                        <div :class="selecionadoAtivo
                          ? 'card-plano-valor-total-selecionado'
                          : 'card-plano-valor-total'
                          ">
                          <div v-if="planoAnual">
                            <div style="display:flex">
                              <div>
                                {{
                                  formatacaoMoeda(
                                    separarNumero(
                                      plano.valorAnualComAssistencia24h
                                    )
                                  )
                                }}
                              </div>
                              <div class="preco-numero">
                                <div>
                                  ,{{
                                    separarNumeroDecimal(
                                      plano.valorAnualComAssistencia24h
                                    )
                                  }}
                                </div>
                                <div v-if="planoAnual" class="card-principal-planos-tipo padrao">
                                  /ano
                                </div>
                                <div v-else-if="planoSemestral" class="card-principal-planos-tipo padrao">
                                  /sem.
                                </div>
                                <div v-else class="card-principal-planos-tipo padrao">
                                  /mês
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-else-if="planoSemestral">
                            <div style="display:flex">
                              <div :class="selecionadoAtivo
                                ? 'padrao-destaque'
                                : 'padrao'
                                ">
                                {{
                                  formatacaoMoeda(
                                    separarNumero(
                                      plano.valorSemestralComAssistencia24h
                                    )
                                  )
                                }}
                              </div>
                              <div class="preco-numero">
                                <div>
                                  ,{{
                                    separarNumeroDecimal(
                                      plano.valorSemestralComAssistencia24h
                                    )
                                  }}
                                </div>
                                <div v-if="planoAnual" class="card-principal-planos-tipo padrao">
                                  /ano
                                </div>
                                <div v-else-if="planoSemestral" class="card-principal-planos-tipo padrao">
                                  /sem.
                                </div>
                                <div v-else class="card-principal-planos-tipo padrao">
                                  /mês
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-else>
                            <div style="display:flex" class="mt-5">
                              <div>
                                {{
                                  formatacaoMoeda(
                                    separarNumero(
                                      plano.valorMensalComAssistencia24h
                                    )
                                  )
                                }}
                              </div>
                              <div class="preco-numero">
                                <div>
                                  ,{{
                                    separarNumeroDecimal(
                                      plano.valorMensalComAssistencia24h
                                    )
                                  }}
                                </div>
                                <div v-if="planoAnual" class="card-principal-planos-tipo padrao">
                                  /ano
                                </div>
                                <div v-else-if="planoSemestral" class="card-principal-planos-tipo padrao">
                                  /sem.
                                </div>
                                <div v-else class="card-principal-planos-tipo padrao">
                                  /mês
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div :style="planoMensal ? 'margin-top:19px' : ''">
                          <div @click.prevent="cardSelecionadoAtivo()" :class="selecionadoAtivo
                            ? 'card-plano-selecionar-selecionado'
                            : 'card-plano-selecionar'
                            ">
                            <span v-if="selecionadoAtivo">
                              Selecionado
                            </span>
                            <span v-else>
                              Selecionar
                            </span>
                          </div>
                        </div>
                      </div>
                      <div v-if="plano.id === config.ID_PLANO_PERFORMANCE"
                        @click.prevent="cardSelecionadoPerformance(plano)" :class="selecionadoPerformance
                          ? 'card-selecionado'
                          : 'card-plano'
                          ">
                        <div :class="selecionadoPerformance
                          ? 'card-plano-titulo-selecionado'
                          : 'card-plano-titulo'
                          ">
                          <span :class="selecionadoPerformance
                            ? 'card-plano-titulo-texto-selecionado'
                            : 'padrao card-plano-titulo-texto'
                            ">DIAMANTE</span>
                        </div>
                        <div class="card-plano-sub-titulo">
                          <span class="card-plano-sub-titulo-nivel">Nível de Cobertura:
                            <span class="card-plano-sub-titulo-nivel-2">11</span>/</span><span
                            class="card-plano-sub-titulo-nivel-2">11</span>
                        </div>
                        <div class="card-plano-nivel">
                          <div class="card-plano-nivel-baixo2">
                            Baixo
                          </div>
                          <div class="card-plano-nivel-medio2">
                            Médio
                          </div>
                          <div class="card-plano-nivel-alto2">
                            Alto
                          </div>
                        </div>
                        <div class="card-plano-nivel mt-2">
                          <div :class="selecionadoPerformance
                            ? 'card-plano-coberturas-selecionado'
                            : 'card-plano-coberturas'
                            " @click.prevent="abrirModalInfor()">
                            Ver Coberturas
                          </div>
                        </div>
                        <div class="card-plano-valor">
                          <div class="card-plano-valor-de">
                            <span v-if="planoAnual">DE:{{
                              formatacaoMoeda2(
                                plano.valorAnualComAssistencia24hMKT
                              )
                            }}</span>
                            <span v-else-if="planoSemestral">DE:{{
                              formatacaoMoeda2(
                                plano.valorSemestralComAssistencia24hMKT
                              )
                            }}</span>
                          </div>
                          <div :class="selecionadoPerformance
                            ? 'card-plano-valor-por-selecionado'
                            : 'card-plano-valor-por'
                            ">
                            <span v-if="planoAnual || planoSemestral">
                              POR:</span>
                          </div>
                        </div>
                        <div :class="selecionadoPerformance
                          ? 'card-plano-valor-total-selecionado'
                          : 'card-plano-valor-total'
                          ">
                          <div v-if="planoAnual">
                            <div style="display:flex">
                              <div>
                                {{
                                  formatacaoMoeda(
                                    separarNumero(
                                      plano.valorAnualComAssistencia24h
                                    )
                                  )
                                }}
                              </div>
                              <div class="preco-numero">
                                <div>
                                  ,{{
                                    separarNumeroDecimal(
                                      plano.valorAnualComAssistencia24h
                                    )
                                  }}
                                </div>
                                <div v-if="planoAnual" class="card-principal-planos-tipo padrao">
                                  /ano
                                </div>
                                <div v-else-if="planoSemestral" class="card-principal-planos-tipo padrao">
                                  /sem.
                                </div>
                                <div v-else class="card-principal-planos-tipo padrao">
                                  /mês
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-else-if="planoSemestral">
                            <div style="display:flex">
                              <div :class="selecionadoPerformance
                                ? 'padrao-destaque'
                                : 'padrao'
                                ">
                                {{
                                  separarNumero(
                                    plano.valorSemestralComAssistencia24h
                                  )
                                }}
                              </div>
                              <div class="preco-numero">
                                <div>
                                  ,{{
                                    separarNumeroDecimal(
                                      plano.valorSemestralComAssistencia24h
                                    )
                                  }}
                                </div>
                                <div v-if="planoAnual" class="card-principal-planos-tipo padrao">
                                  /ano
                                </div>
                                <div v-else-if="planoSemestral" class="card-principal-planos-tipo padrao">
                                  /sem.
                                </div>
                                <div v-else class="card-principal-planos-tipo padrao">
                                  /mês
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-else>
                            <div style="display:flex" class="mt-5">
                              <div>
                                {{
                                  separarNumero(
                                    plano.valorMensalComAssistencia24h
                                  )
                                }}
                              </div>
                              <div class="preco-numero">
                                <div>
                                  ,{{
                                    separarNumeroDecimal(
                                      plano.valorMensalComAssistencia24h
                                    )
                                  }}
                                </div>
                                <div v-if="planoAnual" class="card-principal-planos-tipo padrao">
                                  /ano
                                </div>
                                <div v-else-if="planoSemestral" class="card-principal-planos-tipo padrao">
                                  /sem.
                                </div>
                                <div v-else class="card-principal-planos-tipo padrao">
                                  /mês
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div :style="planoMensal ? 'margin-top:19px' : ''">
                          <div :class="selecionadoPerformance
                            ? 'card-plano-selecionar-selecionado'
                            : 'card-plano-selecionar'
                            ">
                            <span v-if="selecionadoPerformance">
                              Selecionado
                            </span>
                            <span v-else>
                              Selecionar
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mb-5">
                    <div class="text-left mt-2 mb-1">
                      <a href="javascript:void(0)" @click.prevent="abrirModalCotacao()">
                        <i class="fas fa-question-circle text-info"></i> Os
                        valores dessa cotação podem mudar após a vistoria
                      </a>
                    </div>
                    <div class="text-left mb-2">
                      <a href="javascript:void(0)" @click.prevent="abrirModalCotacaoFranquia()">
                        <i class="fas fa-question-circle text-info"></i>
                        Informações da franquia
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </transition>
      <section v-show="step === 9 && lstPlanos">
        <div class="retangulo-flutuante">
          <div class="">
            <div class="row d-flex justify-content-center gap-f">
              <div class="margin-flutuantep mobile text-center">
                <div class="texto-plano-flutuante">Pagamento:</div>
                <div class="texto-plano-flutuante-3">
                  <span v-if="planoSemestral">SEMESTRAL</span>
                  <span v-if="planoAnual">ANUAL</span>
                  <span v-if="planoMensal">MENSAL</span>
                </div>
                <div class="mobile">
                  <div class="row d-flex justify-content-center">
                    <div class="tag-text2" style="width:80px">
                      <span v-if="planoSemestral" class="tag-text-percentual-f">
                        20% OFF</span>
                      <span v-if="planoAnual" class="tag-text-percentual-f">
                        25% OFF</span>
                      <!-- <span v-if="planoMensal" class="tag-text-percentual-f">
                        15% OFF</span
                      > -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="margin-flutuante-escolhido mobile">
                <div class="texto-plano-flutuante">Plano escolhido:</div>
                <div class="texto-plano-flutuante-3" v-if="plano == config.ID_PLANO_URBANO"> PRATA</div>
                <div class="texto-plano-flutuante-3" v-if="plano == config.ID_PLANO_ATIVO"> OURO</div>
                <div class="texto-plano-flutuante-3" v-if="plano == config.ID_PLANO_PERFORMANCE"> DIAMANTE</div>
              </div>
              <div class="margin-flutuanten mobile">
                <div class="texto-plano-flutuante">Nível de Cobertura:</div>
                <div class="texto-plano-flutuante-3">{{ nivelPlano }}</div>
              </div>
              <div class="margin-flutuante-preco" :style="planoMensal ? 'padding-top: 21px;' : 'padding-top: 0px;'
                ">
                <div class="flutuante-de">
                  <span v-if="planoAnual || planoSemestral">DE:</span>
                  <span v-if="planoAnual">{{
                    formatacaoMoeda2(valorMkt)
                  }}</span>
                  <span v-else-if="planoSemestral">{{
                    formatacaoMoeda2(valorMkt)
                  }}</span>
                </div>
                <div class="">
                  <div style="font-family: Inter;font-weight: 700;line-height: 26px;margin-top:2px;text-align: left;">
                    <div class="row">
                      <div class="col-2" style="font-size: 14px;color: #089F17;margin-top: -8px;">
                        POR:
                      </div>
                      <div class="col-9">
                        <div style="display:flex">
                          <div style="margin-left:4px;font-size: 42px;color: #089F17;">
                            {{ formatacaoMoeda(numero) }}
                          </div>
                          <div style="margin-top: -10px;font-size: 12px;color: #089F17;">
                            ,{{ decimal }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="margin-flutuante2">
                <button class="bike-btn-f" :disabled="btn" @click.prevent="selecionouPlano">
                  <span v-if="!loading" class="textt">CONTINUAR</span>
                  <span v-else class="textt">AGUARDE</span>
                  <span style="height: 20px;margin-left:5px">
                    <b-img class="img-button" src="./img/arrow-button.svg"></b-img></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <transition name="slide-fade">
        <section v-show="step === 10" class="step9 mobile-margin">
          <div>
            <div class="d-block d-md-flex justify-content-center ">
              <div>
                <div class="texto-principal">
                  <div class="link-bike" @click.prevent="voltarPasso()">
                    Voltar
                  </div>
                  <div class="padrao text-left mb-4">
                    <span class="padrao-destaque">
                      {{ helper.quebrarNome(nome)[0] }}</span>, {{ animatedText10 }}
                    <div class="mt-2">
                      {{ animatedText10Sub }}
                    </div>
                    <div class="mt-2 sub">
                      {{ animatedText10Sub2 }}
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <div class="card-tipo2 ">
                    <div class="box-shadow " v-for="(plano, index) in lstPlanosOpcional" :key="'planoOpcional' + index">
                      <div v-if="plano.id == config.ID_PLANO_PRATA" @click.prevent="cardSelecionadoPrata(plano)" :class="selecionadoPrata
                        ? 'card-selecionado-opicional'
                        : 'card-plano-opicional'
                        ">
                        <div :class="selecionadoPrata
                          ? 'card-plano-titulo-selecionado'
                          : 'card-plano-titulo'
                          ">
                          <span :class="selecionadoPrata
                            ? 'card-plano-titulo-texto-selecionado'
                            : 'padrao card-plano-titulo-texto'
                            "> {{
                              config.DESCRICAO_PLANO_AP_PRATA_IDENIZACAO
                            }}</span>
                        </div>

                        <div class="text-left" style="padding-right: 2px">
                          <ul style="font-size:10px;margin-bottom: 0px; margin-left: -12px;margin-top: 30px;">
                            <li v-for="(item,
                              index) in config.DESCRICAO_PLANO_AP_PRATA" :key="'u' + index">
                              {{ item }}
                            </li>
                          </ul>
                        </div>

                        <div :class="selecionadoPrata
                          ? 'card-plano-valor-total-selecionado-opicional'
                          : 'card-plano-valor-total-opicional'
                          ">
                          <div v-if="planoAnual">
                            <div style="display:flex;justify-content: center;">
                              <div>
                                {{
                                  formatacaoMoeda(
                                    separarNumero(
                                      plano.valorAnualComAssistencia24h
                                    )
                                  )
                                }}
                              </div>
                              <div class="preco-numero">
                                <div>
                                  ,{{
                                    separarNumeroDecimal(
                                      plano.valorAnualComAssistencia24h
                                    )
                                  }}
                                </div>
                                <div v-if="planoAnual" class="card-principal-planos-tipo padrao">
                                  /ano
                                </div>
                                <div v-else-if="planoSemestral" class="card-principal-planos-tipo padrao">
                                  /sem.
                                </div>
                                <div v-else class="card-principal-planos-tipo padrao">
                                  /mês
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-else-if="planoSemestral">
                            <div style="display:flex; justify-content: center;">
                              <div :class="selecionadoPrata
                                ? 'padrao-destaque'
                                : 'padrao'
                                ">
                                {{
                                  formatacaoMoeda(
                                    separarNumero(
                                      plano.valorSemestralComAssistencia24h
                                    )
                                  )
                                }}
                              </div>
                              <div class="preco-numero">
                                <div>
                                  ,{{
                                    separarNumeroDecimal(
                                      plano.valorSemestralComAssistencia24h
                                    )
                                  }}
                                </div>
                                <div v-if="planoAnual" class="card-principal-planos-tipo padrao">
                                  /ano
                                </div>
                                <div v-else-if="planoSemestral" class="card-principal-planos-tipo padrao">
                                  /sem.
                                </div>
                                <div v-else class="card-principal-planos-tipo padrao">
                                  /mês
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-else>
                            <div style="display:flex;justify-content: center;">
                              <div>
                                {{
                                  formatacaoMoeda(
                                    separarNumero(
                                      plano.valorMensalComAssistencia24h
                                    )
                                  )
                                }}
                              </div>
                              <div class="preco-numero">
                                <div>
                                  ,{{
                                    separarNumeroDecimal(
                                      plano.valorMensalComAssistencia24h
                                    )
                                  }}
                                </div>
                                <div v-if="planoAnual" class="card-principal-planos-tipo padrao">
                                  /ano
                                </div>
                                <div v-else-if="planoSemestral" class="card-principal-planos-tipo padrao">
                                  /sem.
                                </div>
                                <div v-else class="card-principal-planos-tipo padrao">
                                  /mês
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div @click.prevent="cardSelecionadoPrata()" :class="selecionadoPrata
                          ? 'card-plano-selecionar-selecionado'
                          : 'card-plano-selecionar'
                          ">
                          <span v-if="selecionadoPrata">
                            Selecionado
                          </span>
                          <span v-else>
                            Selecionar
                          </span>
                        </div>
                      </div>
                      <div v-if="plano.id == config.ID_PLANO_OURO" @click.prevent="cardSelecionadoOuro(plano)"
                        class="mb-4" :class="selecionadoOuro
                          ? 'card-selecionado-opicional'
                          : 'card-plano-opicional'
                          ">
                        <div :class="selecionadoOuro
                          ? 'card-plano-titulo-selecionado'
                          : 'card-plano-titulo'
                          ">
                          <span :class="selecionadoOuro
                            ? 'padrao card-plano-titulo-texto-selecionado'
                            : 'padrao card-plano-titulo-texto'
                            ">{{
                              config.DESCRICAO_PLANO_AP_OURO_IDENIZACAO
                            }}</span>
                        </div>

                        <div class="text-left" style="padding-right: 2px">
                          <ul style="font-size:10px;margin-bottom: 0px; margin-left: -12px;margin-top: 30px;">
                            <li v-for="(item,
                              index) in config.DESCRICAO_PLANO_AP_OURO" :key="'u' + index">
                              {{ item }}
                            </li>
                          </ul>
                        </div>
                        <div :class="selecionadoOuro
                          ? 'card-plano-valor-total-selecionado-opicional'
                          : 'card-plano-valor-total-opicional'
                          ">
                          <div v-if="planoAnual">
                            <div style="display:flex;justify-content: center;">
                              <div>
                                {{
                                  formatacaoMoeda(
                                    separarNumero(
                                      plano.valorAnualComAssistencia24h
                                    )
                                  )
                                }}
                              </div>
                              <div class="preco-numero">
                                <div>
                                  ,{{
                                    separarNumeroDecimal(
                                      plano.valorAnualComAssistencia24h
                                    )
                                  }}
                                </div>
                                <div v-if="planoAnual" class="card-principal-planos-tipo padrao">
                                  /ano
                                </div>
                                <div v-else-if="planoSemestral" class="card-principal-planos-tipo padrao">
                                  /sem.
                                </div>
                                <div v-else class="card-principal-planos-tipo padrao">
                                  /mês
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-else-if="planoSemestral">
                            <div style="display:flex;justify-content: center;">
                              <div :class="selecionadoOuro ? 'padrao-destaque' : 'padrao'
                                ">
                                {{
                                  formatacaoMoeda(
                                    separarNumero(
                                      plano.valorSemestralComAssistencia24h
                                    )
                                  )
                                }}
                              </div>
                              <div class="preco-numero">
                                <div>
                                  ,{{
                                    separarNumeroDecimal(
                                      plano.valorSemestralComAssistencia24h
                                    )
                                  }}
                                </div>
                                <div v-if="planoAnual" class="card-principal-planos-tipo padrao">
                                  /ano
                                </div>
                                <div v-else-if="planoSemestral" class="card-principal-planos-tipo padrao">
                                  /sem.
                                </div>
                                <div v-else class="card-principal-planos-tipo padrao">
                                  /mês
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-else>
                            <div style="display:flex;justify-content: center;">
                              <div>
                                {{
                                  formatacaoMoeda(
                                    separarNumero(
                                      plano.valorMensalComAssistencia24h
                                    )
                                  )
                                }}
                              </div>
                              <div class="preco-numero">
                                <div>
                                  ,{{
                                    separarNumeroDecimal(
                                      plano.valorMensalComAssistencia24h
                                    )
                                  }}
                                </div>
                                <div v-if="planoAnual" class="card-principal-planos-tipo padrao">
                                  /ano
                                </div>
                                <div v-else-if="planoSemestral" class="card-principal-planos-tipo padrao">
                                  /sem.
                                </div>
                                <div v-else class="card-principal-planos-tipo padrao">
                                  /mês
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div @click.prevent="cardSelecionadoOuro()" :class="selecionadoOuro
                          ? 'card-plano-selecionar-selecionado'
                          : 'card-plano-selecionar'
                          ">
                          <span v-if="selecionadoOuro">
                            Selecionado
                          </span>
                          <span v-else>
                            Selecionar
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </transition>
      <section v-show="step === 10 && lstPlanosOpcional">
        <div class="retangulo-flutuante">
          <div class="">
            <div class="row d-flex justify-content-center">
              <div class="mt-5 mobile">
                <div class="texto-plano-flutuante">Plano escolhido:</div>
                <div class="texto-plano-flutuante-opicional-4 padrao">{{ planoEscolhido }}</div>
              </div>
              <div class="mt-5 mobile mr-3">
                <div style="margin-top:-12px;font-family: Inter;font-weight: 700;text-align: left;">
                  <div style="display:flex;" class="padrao">
                    <div style="margin-left:4px;font-size: 42px;">
                      {{ formatacaoMoeda(numero) }}
                    </div>
                    <div style="margin-top: 11px;font-size: 12px;">
                      ,{{ decimal }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-5 mobile">
                <div>
                  <div class="texto-plano-flutuante">Seguro pessoal:</div>
                  <!-- <div v-if="planoOpcionalEscolhido == config.ID_PLANO_PRATA" class="texto-plano-flutuante-opicional-3">
                    {{config.DESCRICAO_PLANO_AP_PRATA_IDENIZACAO}}
                  </div>
                  <div v-if="planoOpcionalEscolhido == config.ID_PLANO_OURO" class="texto-plano-flutuante-opicional-3">
                    {{config.DESCRICAO_PLANO_AP_OURO_IDENIZACAO}}
                  </div>
                 
                  <div class="n-a text-right" v-if="!planoOpcionalEscolhido">
                    N/A
                  </div> -->
                </div>
              </div>
              <div class="mt-5 mobile mr-3">
                <div style="margin-top:-12px;font-family: Inter;font-weight: 700;text-align: left;">
                  <div style="display:flex;">
                    <div v-if="planoOpcionalEscolhido" style="margin-left:4px;font-size: 42px;color: #089F17;">
                      {{ formatacaoMoeda(numeroOpicional) }}
                    </div>
                    <div v-else style="margin-left:4px;font-size: 42px;color: #089F17;">
                      0
                    </div>
                    <div v-if="planoOpcionalEscolhido" style="margin-top: 11px;font-size: 12px;color: #089F17;">
                      ,{{ decimalOpicional }}
                    </div>
                    <div v-else style="margin-top: 11px;font-size: 12px;color: #089F17;">
                      ,00
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-5 ">
                <div class="texto-plano-flutuante">Total:</div>
              </div>
              <div class="mt-5 margin-desk">
                <div style="margin-top:-12px;font-family: Inter;font-weight: 700;text-align: left;">
                  <div style="display:flex;">
                    <div style="margin-left:4px;font-size: 42px;color: #089F17;">
                      {{ formatacaoMoeda(numeroTotal) }}
                    </div>
                    <div style="margin-top: 11px;font-size: 12px;color: #089F17;">
                      ,{{ decimalTotal }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-5 mx-3" style="
    align-self: center;
">
                <div class="btn-link-f" @click.prevent="criarNovoSeguro(false)">
                  <span class="textt">pular</span>
                </div>
              </div>

              <div class="mt-5">
                <button class="bike-btn-f" :disabled="btn" @click.prevent="criarNovoSeguro(true)">
                  <span v-if="!loading" class="textt">CONTINUAR</span>
                  <span v-else class="textt">AGUARDE</span>
                  <span style="height: 20px;margin-left:5px">
                    <b-img class="img-button" src="./img/arrow-button.svg"></b-img></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <transition name="slide-fade">
        <section v-show="step === 11" class="step9">
          <div class="" style="width: 671px;">
            <div class="texto-principal">
              <div class="link-bike" @click.prevent="voltarPasso()">
                Voltar
              </div>
              <div class="padrao mb-4">
                <span class="padrao-destaque">
                  {{ helper.quebrarNome(nome)[0] }}</span>, {{ animatedText11 }} <br />
                {{ animatedText11Sub }}
              </div>
            </div>

            <ValidationObserver ref="formObserver6">
              <form data-test-id="form-novo-cliente">
                <div class="mt-3">
                  <div class="row">
                    <div class="form-group col-sm-12 col-md-6">
                      <ValidationProvider name="modalidade" mode="passive" rules="required"
                        v-slot="{ errors, classes }">
                        <select class="form-control-select-2" :class="classes" v-model="modalidade"
                          @focus="clearErrors6" data-test-id="input-modalidade">
                          <option :value="undefined">Selecione a modalidade da bike</option>
                          <option v-for="(item, index) in lstModalidade" :key="'modalidade' + index" :value="item.id">
                            {{ item.nome }}
                          </option>
                        </select>
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>

                      <div v-if="loadingModalidade">Carregando...</div>
                    </div>
                    <div class="form-group col-sm-12 col-md-6">
                      <ValidationProvider name="ano" mode="passive" rules="required" v-slot="{ errors, classes }">
                        <select class="form-control-select-2" :class="classes" v-model="ano" @focus="clearErrors6"
                          data-test-id="input-ano-fabricacao">
                          <option :value="undefined">Selecione o ano da bike</option>
                          <option v-for="(item, index) in anoBikes" :key="'ano' + index" :value="item.anoBike">
                            {{ item.anoBike }}
                          </option>
                        </select>
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div>
                    <div class="row">
                      <div class="form-group col-sm-12 col-md-6">
                        <ValidationProvider mode="passive" name="CPF" rules="required|cpf" v-slot="{ errors, classes }">
                          <input :style="cpf ? 'color: #454545;background:#fff;' : ''
                            " :disabled="cpf" type="tel" maxlength="20" class="form-control1" :class="classes"
                            v-model="cpf" @focus="clearErrors6" v-mask="'###.###.###-##'" autocomplete="nope"
                            placeholder="Digite o seu CPF" />
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>

                      <div class="form-group col-sm-12 col-md-6">
                        <ValidationProvider mode="passive" name="RG" rules="required|min:4"
                          v-slot="{ errors, classes }">
                          <input :style="rg ? 'color: #454545;background:#fff;' : ''" type="text" v-model="rg"
                            @focus="clearErrors6" class="form-control1" :class="classes" autocomplete="nope"
                            maxlength="20" placeholder="Digite o seu RG" data-test-id="input-rg" />
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-sm-12 col-md-6">
                        <ValidationProvider mode="passive" name="nascimento"
                          rules="required|data_nascimento|data_maior_idade" v-slot="{ errors, classes }">
                          <input :style="nascimento
                            ? 'color: #454545;background:#fff;'
                            : ''
                            " :readonly="nascimento" type="tel" class="form-control1"
                            placeholder="Digite a sua Data de Nascimento" :class="classes" v-model="nascimento"
                            @focus="clearErrors6" v-mask="'##/##/####'" autocomplete="nope" />
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>

                      <div class="form-group col-sm-12 col-md-6">
                        <ValidationProvider mode="passive" name="telefone" rules="required|min:14"
                          v-slot="{ errors, classes }">
                          <input :style="telefone ? 'color: #454545;background:#fff;' : ''
                            " type="tel" maxlength="20" placeholder="Celular para inspeção" class="form-control1"
                            :class="classes" v-model="telefone" @focus="clearErrors6"
                            v-mask="['(##) ####-####', '(##) #####-####']" autocomplete="nope" />
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </ValidationProvider>

                        <strong class="padrao" style="font-size:12px;">
                          Enviaremos link da vistoria para esse número por
                          WhatsApp.
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 text-center mb-5">
                    <button class="bike-btn" data-test-id="btn-continuar" @click.prevent="formObserver6"
                      :disabled="btn">
                      <span v-if="!loading" class="text">CONTINUAR</span>
                      <span v-else class="text">AGUARDE</span>
                      <b-img class="img-button" src="./img/arrow-button.svg"></b-img>
                    </button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </section>
      </transition>
      <transition name="slide-fade">
        <section v-show="step === 12" class="step9">
          <div class="" style="width: 683px;">
            <div class="texto-principal">
              <div class="link-bike" @click.prevent="voltarPasso()">
                Voltar
              </div>
              <div class="padrao mb-4">
                <span class="padrao-destaque">
                  {{ helper.quebrarNome(nome)[0] }}</span>,{{ animatedText12 }}
              </div>
            </div>

            <ValidationObserver ref="formObserver7">
              <form data-test-id="form-novo-cliente">
                <div class="mt-3">
                  <div class="row">
                    <div class="form-group col-md-12 col-lg-4">
                      <ValidationProvider name="CEP" mode="passive" rules="required|min:9" v-slot="{ errors, classes }">
                        <input :style="cep ? 'color: #454545;background:#fff;' : ''" type="tel"
                          placeholder="Digite o CEP" maxlength="20" class="form-control1" :class="classes" v-model="cep"
                          @focus="clearErrors7" v-mask="'#####-###'" autocomplete="nope" data-test-id="input-cep" />
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <div v-if="loadingCep">
                        <small>Carregando...</small>
                      </div>
                    </div>
                    <div class="form-group col-md-12 col-lg-8">
                      <ValidationProvider name="endereço" mode="passive" rules="required|min:3"
                        v-slot="{ errors, classes }">
                        <input :style="logradouro ? 'color: #454545;background:#fff;' : ''
                          " type="text" placeholder="Endereço" class="form-control1" :class="classes"
                          v-model="logradouro" @focus="clearErrors7" autocomplete="nope" maxlength="100"
                          data-test-id="input-endereco" />
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-12 col-lg-3">
                      <ValidationProvider name="número" mode="passive" rules="required" v-slot="{ errors, classes }">
                        <input :style="numEndereco ? 'color: #454545;background:#fff;' : ''
                          " type="tel" v-model="numEndereco" @focus="clearErrors7" placeholder="Número"
                          class="form-control1" :class="classes" autocomplete="nope" maxlength="10"
                          v-mask="'##########'" data-test-id="input-numero-endereco" />
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group col-md-12 col-lg-4">
                      <ValidationProvider name="estado" mode="passive" rules="required" v-slot="{ errors, classes }">
                        <select class="form-control-select-2" v-model="estado" @focus="clearErrors7" :class="classes"
                          data-test-id="input-estado">
                          <option :value="undefined">Selecione sua UF</option>
                          <option value="AC">Acre</option>
                          <option value="AL">Alagoas</option>
                          <option value="AM">Amazonas</option>
                          <option value="AP">Amapá</option>
                          <option value="BA">Bahia</option>
                          <option value="CE">Ceará</option>
                          <option value="DF">Distrito Federal</option>
                          <option value="ES">Espírito santo</option>
                          <option value="GO">Goiás</option>
                          <option value="MA">Maranhão</option>
                          <option value="MG">Minas Gerais</option>
                          <option value="MS">Mato Grosso do sul</option>
                          <option value="MT">Mato Grosso</option>
                          <option value="PA">Pará</option>
                          <option value="PB">Paraíba</option>
                          <option value="PE">Pernambuco</option>
                          <option value="PI">Piauí</option>
                          <option value="PR">Paraná</option>
                          <option value="RJ">Rio de Janeiro</option>
                          <option value="RN">Rio Grande do Norte</option>
                          <option value="RO">Roraima</option>
                          <option value="RR">Rondônia</option>
                          <option value="RS">Rio Grande do Sul</option>
                          <option value="SC">Santa Catarina</option>
                          <option value="SE">Sergipe</option>
                          <option value="SP">São Paulo</option>
                          <option value="TO">Tocantins</option>
                        </select>
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group col-md-12 col-lg-5">
                      <ValidationProvider name="cidade" mode="passive" rules="required|min:3"
                        v-slot="{ errors, classes }">
                        <input :style="cidade ? 'color: #454545;background:#fff;' : ''
                          " type="text" class="form-control1" placeholder="Cidade" :class="classes" v-model="cidade"
                          @focus="clearErrors7" autocomplete="nope" maxlength="50" data-test-id="input-cidade" />
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-12 col-lg-6">
                      <ValidationProvider name="bairro" mode="passive" rules="required|min:3"
                        v-slot="{ errors, classes }">
                        <input :style="bairro ? 'color: #454545;background:#fff;' : ''
                          " type="text" placeholder="Bairro" class="form-control1" :class="classes" v-model="bairro"
                          @focus="clearErrors7" autocomplete="nope" maxlength="100" data-test-id="input-bairro" />
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group col-md-12 col-lg-6">
                      <input :style="complemento ? 'color: #454545;background:#fff;' : ''
                        " type="text" class="form-control1" placeholder="Complemento (opcional)" v-model="complemento"
                        autocomplete="nope" maxlength="100" data-test-id="input-complemento" />
                    </div>
                  </div>
                </div>

                <div class="text-center" style="margin-bottom: 50px;">
                  <button class="bike-btn" data-test-id="btn-continuar" @click.prevent="formObserver7" :disabled="btn">
                    <span v-if="!loading" class="text">CONTINUAR</span>
                    <span v-else class="text">AGUARDE</span>
                    <b-img class="img-button" src="./img/arrow-button.svg"></b-img>
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </section>
      </transition>

      <section v-show="step === 13" class="step9">
        <div style="width:695px">
          <div class="texto-principal">
            <div class="link-bike" @click.prevent="voltarPasso()">
              Voltar
            </div>
            <div class="padrao mb-3">
              Perfeito<span class="padrao-destaque">
                {{ helper.quebrarNome(nome)[0] }}</span>! {{ animatedText13 }}
            </div>
            <div class="mt-3 text-left" style="font-size:10px;">
              <p>
                <span class="sub"> {{ animatedText13Sub }}</span>
              </p>
              <span class="sub">
                {{ animatedText13Sub2 }}
              </span>
            </div>
          </div>
          <ValidationObserver v-slot="{ handleSubmit }">
            <form id="formPagamento" @submit.prevent="handleSubmit()" data-test-id="form-pagamento">
              <div class="texto-principal">
                <div class="text-right">
                  <b-img width="90px" src="./img/ssl-site-seguro.webp"></b-img>
                </div>

                <div class="padrao text-left my-2 card-paid">
                  <div class="row">
                    <div class="col-md-12 col-lg-6 text-card-paid ">
                      <div class="row">
                        <div class="mb-2 col-7">
                          Plano escolhido:
                          <div class="padrao-destaque" style="text-transform: uppercase;"
                            v-if="plano == config.ID_PLANO_URBANO">PRATA</div>
                          <div class="padrao-destaque" style="text-transform: uppercase;"
                            v-if="plano == config.ID_PLANO_ATIVO">OURO</div>
                          <div class="padrao-destaque" style="text-transform: uppercase;"
                            v-if="plano == config.ID_PLANO_PERFORMANCE">DIAMANTE</div>
                        </div>
                        <div class="col-5 text-right padrao-destaque" style="align-self: self-center;">
                          <span style="color: #089F17;" v-if="!cupomAplicado">
                            {{
                              formatacaoMoeda2(valorPlano - valorPlanoAcessorio)
                            }}
                          </span>
                          <span style="color: #089F17;" v-if="cupomAplicado">
                            {{ formatacaoMoeda2(valorPlanoEscolhido) }}
                          </span>
                          <span v-if="planoAnual" class="card-principal-planos-tipo">
                            /ano
                          </span>
                          <span v-else-if="planoSemestral" class="card-principal-planos-tipo">
                            /sem.
                          </span>
                          <span v-else class="card-principal-planos-tipo">
                            /mês
                          </span>
                        </div>
                      </div>
                      <div class="row" v-if="valorPlanoAcessorio">
                        <div class="mb-2 col-8">
                          Plano acessório:
                          <div class="padrao-destaque" style="text-transform: uppercase;">
                            {{ marcaAcessorio }}
                          </div>
                        </div>
                        <div class="col-4 text-right padrao-destaque" style="align-self: self-center;">
                          <span style="color: #089F17;" v-if="!cupomAplicado">
                            {{ formatacaoMoeda2(valorPlanoAcessorio) }}
                          </span>
                          <span style="color: #089F17;" v-if="cupomAplicado">
                            {{ formatacaoMoeda2(valorPlanoAcessorioEscolhido) }}
                          </span>
                          <span v-if="planoAnual" class="card-principal-planos-tipo">
                            /ano
                          </span>
                          <span v-else-if="planoSemestral" class="card-principal-planos-tipo">
                            /sem.
                          </span>
                          <span v-else class="card-principal-planos-tipo">
                            /mês
                          </span>
                        </div>
                      </div>
                      <hr style="margin-top:0px" v-if="valorPlanoOpcionalEscolhido" />
                      <div class="row" v-if="valorPlanoOpcionalEscolhido">
                        <div class="mb-2 col-8">
                          Seguro pessoal:
                          <div v-if="planoOpcionalEscolhido == config.ID_PLANO_PRATA" class="padrao-destaque"
                            style="text-transform: uppercase;">
                            {{ config.DESCRICAO_PLANO_AP_PRATA_IDENIZACAO }}
                          </div>
                          <div v-if="planoOpcionalEscolhido == config.ID_PLANO_OURO" class="padrao-destaque"
                            style="text-transform: uppercase;">
                            {{ config.DESCRICAO_PLANO_AP_OURO_IDENIZACAO }}
                          </div>
                          <div class="n-a mr-3" v-if="!planoOpcionalEscolhido">
                            N/A
                          </div>
                        </div>
                        <div class="col-4 text-right padrao-destaque" style="align-self: self-center;">
                          <div v-if="valorPlanoOpcionalEscolhido">
                            <span style="color: #089F17;">
                              {{
                                formatacaoMoeda2(valorPlanoOpcionalEscolhido)
                              }}
                            </span>
                            <span v-if="planoAnual" class="card-principal-planos-tipo">
                              /ano
                            </span>
                            <span v-else-if="planoSemestral" class="card-principal-planos-tipo">
                              /sem.
                            </span>
                            <span v-else class="card-principal-planos-tipo">
                              /mês
                            </span>
                          </div>
                          <div class="n-a" v-else>
                            N/A
                          </div>
                        </div>
                      </div>
                      <hr style="margin-top:0px" v-if="valorPlanoAcessorio || valorPlanoOpcionalEscolhido
                      " />
                      <div class="row" v-if="valorPlanoAcessorio || valorPlanoOpcionalEscolhido
                      ">
                        <div class="mb-2 col-7 text-right" style="align-self: self-end;">
                          Total:
                        </div>
                        <div class="col-5 text-right padrao-destaque" style="align-self: self-center;">
                          <span style="color: #089F17;" v-if="!cupomAplicado">
                            {{ formatacaoMoeda2(valorTotalSeguro) }}
                          </span>
                          <span style="color: #089F17;" v-if="cupomAplicado">
                            {{ formatacaoMoeda2(valorTotalSeguroEscolhido) }}
                          </span>

                          <span v-if="planoAnual" class="card-principal-planos-tipo">
                            /ano
                          </span>
                          <span v-else-if="planoSemestral" class="card-principal-planos-tipo">
                            /sem.
                          </span>
                          <span v-else class="card-principal-planos-tipo">
                            /mês
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-6 mobile" v-show="!loading">
                      <div class="card-wrapper"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-8 mb-3">
                  <ValidationProvider name="número do cartão" rules="required" v-slot="{ errors, classes }">
                    <input type="tel" :style="numCartao ? 'color: #454545;background:#fff;' : ''
                      " class="form-control1" v-mask="'#### #### #### ####'" :class="classes" name="cardNumber"
                      v-model="numCartao" autocomplete="nope" placeholder="Número do cartão" />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="col-md-4 mb-3">
                  <ValidationProvider name="validade" rules="required|min:5|expiracao_cartao"
                    v-slot="{ errors, classes }">
                    <input type="tel" :style="validadeCartao ? 'color: #454545;background:#fff;' : ''
                      " class="form-control1" :class="classes" placeholder="Validade" v-mask="'##/##'"
                      v-model="validadeCartao" autocomplete="nope" />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="col-md-8 mb-3">
                  <ValidationProvider name="nome" rules="required|min:10" v-slot="{ errors, classes }">
                    <input type="text" class="form-control1" :style="nomeCartao
                      ? 'color: #454545;background:#fff; text-transform: uppercase;'
                      : ''
                      " :class="classes" name="cardName" v-model="nomeCartao" maxlength="100" autocomplete="nope"
                      placeholder="Nome (como descrito no cartão)" />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="col-md-4 mb-3">
                  <ValidationProvider name="cvc" rules="required|min:2" v-slot="{ errors, classes }">
                    <input type="tel" class="form-control1" :class="classes" name="cardCvc" :style="cvcCartao ? 'color: #454545;background:#fff;' : ''
                      " v-model="cvcCartao" autocomplete="nope" placeholder="CVC" />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="col-sm-12 col-md-6" v-if="
                  idTipoAssinatura == config.ID_TIPO_ASSINATURA_SEMESTRAL
                  || idTipoAssinatura == config.ID_TIPO_ASSINATURA_ANUAL
                ">
                  <ValidationProvider name="parcela" mode="passive" rules="required" v-slot="{ errors, classes }">
                    <select class="form-control-select-2" :style="parcela && 'color: black;font-weight: 600;'"
                      v-model="parcela" :class="classes">
                      <option v-for="(item, index) in parcelas" :key="'parcela' + index" :value="item.p">
                        Parcela {{ index + 1 }}x de {{ item.valor }}
                      </option>
                    </select>
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div @click.prevent="abrirModalCupom()" class="col-md-12 mt-3 pointer text-center" v-if="esconderCupom">
                  <div v-show="!cupomAplicado">
                    <b-alert show>
                      Tem cupom de desconto? Clique aqui para aplicar
                    </b-alert>
                  </div>
                  <div v-show="cupomAplicado">
                    <b-alert show> Cupom aplicado: {{ cupom }} </b-alert>
                  </div>
                </div>
              </div>
              <div class="row d-flex justify-content-center mb-5">
                <button class="bike-btn" type="submit" @click.prevent="callback" :disabled="btn">
                  <span v-if="!loading" class="text">Finalizar</span>
                  <span v-else class="text">Aguarde</span>
                  <b-img class="img-button" src="./img/arrow-button.svg"></b-img>
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </section>
    </div>
    <ModalInforPlanos :showModalPlanos="showModalPlanos" :titulo="titulo" @hide="hideModalInfor" />
    <ModalCupom :showModalCupom="showModalCupom" :cupomAplicado="cupomAplicado" :limpar="limpar" @hide="hideModalCupom"
      @remove="removerDesconto" @aplicar="onClickAplicarCupom" />
    <ModalOrientacaoPlano :show="abrirModal" @hide="hideModal" />
    <ModalOrientacaoFranquia :show="abrirModalFranquia" :valorBike="valorBike" @hide="hideModalFranquia" />
    <ModalMelhorOferta :show="showMelhorOferta" :nome="helper.quebrarNome(nome)[0]" @hide="recusouMelhorOferta"
      @want="aceitouMelhorOferta" />
    <Loading :criandoSeguro="aguarde" v-if="loading" />
  </div>
</template>

<script>
import auth from "../auth";
import axios from "axios";
import Swal from "sweetalert2";
import config from "../config";
import formatacaoMoeda from "../helpers/formatacaoMoeda";
import formatacaoMoeda2 from "../helpers/formatacaoMoeda2";
import anoBikes from "../data/anoBikesSeguro";
import Card from "card";
import helper from "../helpers/helper";
import Loading from "../components/Usuario/Loading.vue";
import moment from "moment";
import { Money } from "v-money";
export default {
  components: {
    Money,
    Loading,
    ModalInforPlanos: () => import("../components/Modal/ModalInforPlanos.vue"),
    ModalCupom: () => import("../components/Modal/ModalCupom.vue"),
    ModalOrientacaoPlano: () =>
      import("../components/Usuario/ModalOrientacaoPlano.vue"),
    ModalOrientacaoFranquia: () =>
      import("../components/Usuario/ModalOrientacaoFranquia.vue"),
    ModalMelhorOferta: () =>
      import("../components/Modal/ModalMelhorOferta.vue"),
  },
  data() {
    return {
      helper,
      anoBikes,
      config,
      auth,
      showModalCupom: false,
      loading: false,
      aguarde: false,
      formatacaoMoeda,
      formatacaoMoeda2,
      step: 0,
      nome: "",
      nascimento: undefined,
      email: "",
      telefone: "",
      cpf: "",

      modeloBike: undefined,
      marcaBike: undefined,
      valorBike: 0,
      loadingMarca: false,
      lstMarcas: [],
      idTipoAssinatura: undefined,
      estado: undefined,
      nomeMarcaBike: undefined,

      parcela: 1,
      parcelas: undefined,

      showModalPlanos: false,
      titulo: "",

      idSeguro: undefined,

      //step 2
      competicoesInternacionais: false,

      //planos
      lstPlanos: [],
      buscandoPlanos: false,

      //novo Seguro
      plano: undefined,
      valorPlano: undefined,
      valorPlanoString: "",

      //indicacao
      codLinkIndicacao: undefined,

      abrirModal: false,
      abrirModalFranquia: false,

      planoMensal: false,

      //Plano anual
      planoAnual: true,
      urlParceiro: undefined,

      //Plano semestral
      planoSemestral: false,
      activeSemestral: undefined,

      //Seguro ap
      seguroAp: false,
      lstPlanosOpcional: [],

      //Cobertura acessório
      coberturaAcessorio: false,
      marcaAcessorio: undefined,
      nomeAcessorio: undefined,
      valorAcessorio: 0,
      valorPlanoAcessorio: undefined,

      //step 1
      ano: undefined,
      modalidade: undefined,
      lstModalidade: [],
      loadingModalidade: false,

      //step 2 Informações pessoais
      idCliente: undefined,
      bloqueioCpf: false,
      rg: undefined,

      //Endereço
      cep: undefined,
      logradouro: undefined,
      cidade: undefined,
      bairro: undefined,
      numEndereco: undefined,
      complemento: undefined,
      loadingCep: false,
      exibirCamposEndereco: false,

      //Pagamento
      numCartao: undefined,
      validadeCartao: undefined,
      cvcCartao: undefined,
      nomeCartao: undefined,
      loadingFinalizar: false,

      //Plano
      planoEscolhido: undefined,
      valorPlanoEscolhido: 0,
      valorPlanoAcessorioEscolhido: undefined,

      //Cupom
      cupom: undefined,
      loadingCupom: false,
      cupomAplicado: false,
      esconderCupom: false,
      valorCupom: 0,
      cupomAtivo: false,
      selecionado: "card-selecionado",
      selecionadoPerformance: false,
      selecionadoAtivo: true,
      selecionadoUrbano: false,
      selecionadoOuro: false,
      selecionadoPrata: false,
      planoSelecionadoOpcional: {},
      planoSelecionado: {},
      nivelPlano: "MÉDIO",

      texto0: "",
      texto0Sub: "",
      animatedText0: "",
      animatedText0Sub: "",

      texto1: "",
      animatedText1: "",

      texto2: "",
      animatedText2: "",

      texto3: "",
      animatedText3: "",
      texto3Sub: "",
      animatedText3Sub: "",

      texto4: "",
      animatedText4: "",
      texto4Sub: "",
      animatedText4Sub: "",

      texto5: "",
      animatedText5: "",

      texto6: "",
      animatedText6: "",

      texto7: "",
      animatedText7: "",

      texto9: "",
      animatedText9: "",

      texto10: "",
      animatedText10: "",
      texto10Sub: "",
      animatedText10Sub: "",
      texto10Sub2: "",
      animatedText10Sub2: "",

      texto11: "",
      animatedText11: "",
      texto11Sub: "",
      animatedText11Sub: "",

      texto12: "",
      animatedText12: "",

      texto13: "",
      animatedText13: "",
      texto13Sub: "",
      animatedText13Sub: "",
      texto13Sub2: "",
      animatedText13Sub2: "",
      texto14: "",
      animatedText14: "",
      texto14Sub: "",
      animatedText14Sub: "",

      texto15: "",
      animatedText15: "",
      texto15Sub: "",
      animatedText15Sub: "",

      texto17: "",
      animatedText17: "",
      texto17Sub: "",
      animatedText17Sub: "",

      originalText: "",
      originalText2: "",
      originalText3: "",
      animatedText: "",

      textIndex: 0,
      textIndex2: 0,
      textIndex3: 0,
      showText: false, // Inicialmente, o texto está oculto
      numero: 0,
      decimal: 0,
      numeroOpicional: 0,
      decimalOpicional: 0,
      valorTotalSeguro: 0,
      valorTotalSeguroEscolhido: 0,
      numeroTotal: 0,
      decimalTotal: 0,
      valorPlanoOpcionalEscolhido: 0,
      planoOpcionalEscolhido: 0,
      valorMkt: 0,
      limpar: false,
      btn: false,
      valorAcessorioString: "",
      notaFiscal: false,
      ehMozila: false,
      primeiroRecaptia: false,
      ufCotacao: undefined,
      showMelhorOferta: false,
    };
  },
  created() {
    this.buscarMarcas();
    this.idTipoAssinatura = config.ID_TIPO_ASSINATURA_ANUAL;
    this.popularInformacoes();
    this.codLinkIndicacao = helper.getUrlParameter("i");

  },
  mounted() {
    const userAgent = navigator.userAgent;

    if (userAgent.includes("Firefox")) {
      this.ehMozila = true;
    }
    if (userAgent.includes("Firefox")) {
      this.ehMozila = true;
    }
    initCard();
    setTimeout(() => {
      this.showText = true; // Mostrar o texto após um atraso inicial
      this.typeText(this.step);
    }, 1000);
    setTimeout(() => {
      this.step++;
      this.originalText = "";
      this.animatedText = "";
      this.typeText(this.step);
    }, 2700);
  },

  watch: {

    valorAcessorioString: function (newValue) {
      let valor = newValue.replace(/[^\d,.]/g, "");
      this.valorAcessorio = parseFloat(
        valor.replace(".", "").replace(",", ".")
      );
    },
    valorPlanoString: function (newValue) {
      let valor = newValue.replace(/[^\d,.]/g, "");
      this.valorBike = parseFloat(valor.replace(".", "").replace(",", "."));
    },
    cupom: function (newValue) {
      this.cupom = newValue.toUpperCase().trim();
    },
    step: function (value) {
      if (this.step == 3) {
        this.primeiroRecaptia = true;
      } else {
        this.primeiroRecaptia = false;
      }

      if (this.step == 7 && this.valorBike > 0) {
        this.atualizarNovoSeguroLead();
      } else if (value === 9) {
        this.atualizarNovoSeguroLead();
      } else if (value === 10 && this.planoSelecionadoOpcional) {
        if (this.selecionadoOuro) {
          this.cardSelecionadoOuro(this.planoSelecionadoOpcional);
        } else {
          this.cardSelecionadoPrata(this.planoSelecionadoOpcional);
        }
      } else if (value === 11) {
        this.buscarModalidades();
        this.oterFetureFlagCupom();
      } else if (this.step == 12) {
        this.atualizarDadosBike();
        if (this.idTipoAssinatura == config.ID_TIPO_ASSINATURA_MENSAL) {
          this.carregarIugu(false);
        }
        else {
          this.carregarIugu(true);

        }

      } else if (this.step == 13) {
        this.atualizarEndereco();
        this.numCartao = undefined;
        this.validadeCartao = undefined;
        this.cvcCartao = undefined;
        this.nomeCartao = undefined;

      }

      if (value == 9) {
        this.selecionadoOuro = false;
        this.selecionadoPrata = false;
        this.numeroOpicional = 0;
        this.decimalOpicional = 0;
        this.planoOpcionalEscolhido = 0;
        this.valorPlanoOpcionalEscolhido = 0;
        this.buscarPlanos();
      }
    },
    email: function (value) {
      this.email = value.replace(/\s+/g, "").toLowerCase();
    },
    valorPlano: function (value) {
      this.valorTotalSeguro = 0;
      this.separarDecimal(value);
    },
    modeloBike: function (value) {
      this.modeloBike = value.toUpperCase();
    },
    marcaAcessorio: function (value) {
      this.marcaAcessorio = value?.toUpperCase();
    },
    cep: function (value) {
      clearTimeout(window["debounceCep"]);
      window["debounceCep"] = setTimeout(() => {
        this.buscarEnderecoCep(value);
      }, 700);
    },
    marcaBike: function (value) {
      if (value) {
        this.nomeMarcaBike = value.nome;
      }
    },
  },
  methods: {
    async aceitouMelhorOferta() {
      this.showMelhorOferta = false;

      this.btnPlanoAnual();

      let planoResponse = this.lstPlanos.filter(
        (w) => w.id === config.ID_PLANO_PERFORMANCE
      );

      this.cardSelecionadoPerformance(planoResponse[0]);
    },
    async recusouMelhorOferta() {
      this.showMelhorOferta = false;
      this.proximoPasso();
    },
    carregarIugu(value) {
      //Configurando pagamento via Iugu
      if (window["Iugu"]) {
        if (value) {
          window["Iugu"].setAccountID(config.IUGU_ACCOUNTB);
          this.contaB = true;
        } else {
          window["Iugu"].setAccountID(config.IUGU_ACCOUNT);
          this.contaB = false;
        }
        window["Iugu"].setTestMode(config.IUGU_TEST_MODE);
      }
    },

    async buscarParcelas() {
      try {
        var response = await axios.get(
          `${config.API_URLV2}/pagamentoSeguro/ObterValorParcela?valorPlano=${this.valorTotalSeguro}`
        );

        if (this.idTipoAssinatura == config.ID_TIPO_ASSINATURA_ANUAL) {
          this.parcelas = response.data.anual;
        }
        if (this.idTipoAssinatura == config.ID_TIPO_ASSINATURA_SEMESTRAL) {
          this.parcelas = response.data.semestral;
        }

      } catch (error) {
        this.validacoes(error);
      }
    },
    validate() {
      // validate your form , if you don't have validate prop , default validate pass .

      if (this.primeiroRecaptia) {
        if (this.step === 3 && this.telefone.length === 15) {
          return true;
        } else {
          return false;
        }
      } else {
        if (
          this.numCartao &&
          this.validadeCartao &&
          this.cvcCartao &&
          this.nomeCartao &&
          !this.loading
        ) {
          this.primeiroRecaptia = false;
          return true;
        } else {
          return false;
        }
      }
    },
    async callback() {
      if (!this.validate()) return;

      if (this.primeiroRecaptia) {
        let result = await this.salvarLead();
        if (result) {
          await this.proximoPasso();
        }
      } else {
        this.loading = true;
        await this.finalizarSeguro();
      }
    },
    getCurrencyMask(value) {
      if (value.length <= 6) {
        return "R$ ###";
      } else if (value.length <= 8) {
        return "R$ #.###";
      } else if (value.length <= 9) {
        return "R$ ##.###";
      } else if (value.length <= 11) {
        return "R$ #.###.###";
      } else if (value.length <= 12) {
        return "R$ ##.###.###";
      } else {
        return "R$ ###.###.###";
      }
    },
    clearErrors() {
      this.$nextTick(() => {
        this.$refs.formObserver.reset(); // Reset validation errors
      });
    },
    clearErrors1() {
      this.$nextTick(() => {
        this.$refs.formObserver1.reset(); // Reset validation errors
      });
    },
    clearErrors2() {
      this.$nextTick(() => {
        this.$refs.formObserver2.reset(); // Reset validation errors
      });
    },
    clearErrors3() {
      this.$nextTick(() => {
        this.$refs.formObserver3.reset(); // Reset validation errors
      });
    },
    clearErrors4() {
      this.$nextTick(() => {
        this.$refs.formObserver4.reset(); // Reset validation errors
      });
    },
    clearErrors5() {
      this.$nextTick(() => {
        this.$refs.formObserver5.reset(); // Reset validation errors
      });
    },
    clearErrors6() {
      this.$nextTick(() => {
        this.$refs.formObserver6.reset(); // Reset validation errors
      });
    },
    clearErrors7() {
      this.$nextTick(() => {
        this.$refs.formObserver7.reset(); // Reset validation errors
      });
    },
    clearErrors8() {
      this.$nextTick(() => {
        this.$refs.formObserver8.reset(); // Reset validation errors
      });
    },
    async formObserver() {
      const passes = await this.$refs.formObserver.validate();
      if (passes) {
        this.proximoPasso();
      }
    },
    async formObserver1() {
      const passes = await this.$refs.formObserver1.validate();
      if (passes) {
        this.proximoPasso();
      }
    },
    async formObserver2() {
      const passes = await this.$refs.formObserver2.validate();
      if (passes) {
        this.loading = true;
        let continua = await this.verificarPodeContinuar();
        if (continua) {
          this.btn = true;
          let stepdefult = 5;
          //para aplicar o efeito
          this.step = 22;
          setTimeout(() => {
            this.step = stepdefult;
            this.typeText(this.step);
          }, 800);
        }
      }
    },
    async formObserver3() {
      const passes = await this.$refs.formObserver3.validate();
      if (passes) {
        let result = await this.salvarLead();
        if (result) {
          this.btn = true;
          let stepdefult = 17;
          //para aplicar o efeito
          this.step = 22;
          setTimeout(() => {
            this.step = stepdefult;
            this.typeText(this.step);
          }, 800);
        }

      }
    },
    async formObserver4() {
      const passes = await this.$refs.formObserver4.validate();
      if (passes) {

        this.ufCotacao = this.estado;

        if (!this.valorBike || this.valorBike === 0) {
          this.valorPlanoString = "";
          await this.formObserver4();
          return;
        } else if (this.valorBike > config.BIKE_VALOR_MAXIMO) {
          this.step = 22;
          this.btn = true;
          setTimeout(() => {
            this.originalText = "";
            this.originalText2 = "";
            this.originalText3 = "";
            this.animatedText2 = "";
            this.animatedText = "";
            this.animatedText3 = "";
            this.step = 14;
            this.typeText(this.step);
          }, 800);
          return;
        } else {
          //para aplicar o efeito
          this.step = 22;
          this.btn = true;
          setTimeout(() => {
            this.originalText = "";
            this.originalText2 = "";
            this.originalText3 = "";
            this.animatedText2 = "";
            this.animatedText = "";
            this.animatedText3 = "";
            this.step = 15;
            this.typeText(this.step);
          }, 800);
        }
      }
    },
    async formObserver5() {
      const passes = await this.$refs.formObserver5.validate();

      if (passes) {
        if (!this.valorAcessorio || this.valorAcessorio === 0) {
          this.valorAcessorioString = "";
          await this.formObserver5();
          return;
        }
        this.notaFiscal = true;
        this.proximoPasso();
      }
    },
    async formObserver6() {
      const passes = await this.$refs.formObserver6.validate();
      if (passes) {
        this.proximoPasso();
      }
    },
    async formObserver7() {
      const passes = await this.$refs.formObserver7.validate();
      if (passes) {
        this.proximoPasso();
      }
    },
    async formObserver8() {
      const passes = await this.$refs.formObserver8.validate();
      if (passes) {
        //this.finalizarSeguro();
      }
    },
    removerDesconto() {
      this.limpar = true;
      this.showModalCupom = false;
      var strDadosLocalStorage = sessionStorage.getItem("dadosSimulacao");
      var dadosCotacao = JSON.parse(strDadosLocalStorage);
      this.valorPlanoEscolhido = this.valorPlano;
      this.valorPlanoAcessorioEscolhido = this.valorPlanoAcessorio;
      this.valorPlanoOpcionalEscolhido = dadosCotacao.valorPlanoOpcional;
      this.valorTotalSeguroEscolhido = 0;
      this.cupom = undefined;
      this.cupomAplicado = false;
      this.cupomAtivo = false;
    },
    separarDecimal(numero) {
      if (!numero) return;
      this.valorPlanoOpcionalEscolhido = 0;
      const partes = numero.toString().split(".");
      this.numero = partes[0];
      if (partes?.length > 1) {
        if (partes[1]?.length == 1) {
          this.decimal = partes[1]?.toString() + "0";
        } else {
          this.decimal = partes[1];
        }
      } else {
        this.decimal = "00";
      }
      this.valorTotalSeguro = numero;
      this.separarDecimalTotal(this.valorTotalSeguro);
    },
    separarDecimalOpicional(numero) {
      if (!numero) return;
      let valorTotal = this.valorPlano + numero;
      this.valorTotalSeguro = parseFloat(valorTotal.toFixed(2));
      const partes = numero.toString().split(".");
      this.numeroOpicional = partes[0];
      if (partes?.length > 1) {
        if (partes[1]?.length == 1) {
          this.decimalOpicional = partes[1].toString() + "0";
        } else {
          this.decimalOpicional = partes[1];
        }
      } else {
        this.decimalOpicional = "00";
      }
      if (numero) {
        this.separarDecimalTotal(this.valorTotalSeguro);
      }
    },
    separarDecimalTotal(numero) {
      if (!numero) return;
      const partes = numero.toString().split(".");
      this.numeroTotal = partes[0];
      if (partes?.length > 1) {
        if (partes[1]?.length == 1) {
          this.decimalTotal = partes[1].toString() + "0";
        } else {
          this.decimalTotal = partes[1].substring(0, 2);
        }
      } else {
        this.decimalTotal = "00";
      }
    },
    separarNumero(numero) {
      if (!numero) return;
      const partes = numero.toString().split(".");
      return partes[0];
    },
    separarNumeroDecimal(numero) {
      if (!numero) return;
      const partes = numero.toString().split(".");
      let valor = partes[1];
      if (valor?.length == 0 || valor == null) {
        valor = "00";
      }
      if (valor?.length == 1) {
        valor = valor.toString() + "0";
      }
      if (valor?.length > 1) {
        valor = valor.substring(0, 2);
      }

      return valor;
    },
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async textSpeak() {
      this.animatedText0 = "";
      this.animatedText0Sub = "";
      for (let index = 0; index < this.texto0?.length; index++) {
        this.animatedText0 += this.texto0.charAt(index);
        await this.delay(20);
      }

      for (let index = 0; index < this.texto0Sub?.length; index++) {
        this.animatedText0Sub += this.texto0Sub.charAt(index);
        await this.delay(15);
      }
    },
    async textSpeak1() {
      this.animatedText1 = "";
      for (let index = 0; index < this.texto1?.length; index++) {
        this.animatedText1 += this.texto1.charAt(index);
        await this.delay(20);
      }
    },
    async textSpeak2() {
      this.animatedText2 = "";
      for (let index = 0; index < this.texto2?.length; index++) {
        this.animatedText2 += this.texto2.charAt(index);
        await this.delay(20);
      }
    },
    async textSpeak3() {
      this.animatedText3 = "";
      this.animatedText3Sub = "";
      for (let index = 0; index < this.texto3?.length; index++) {
        this.animatedText3 += this.texto3.charAt(index);
        await this.delay(20);
      }

      for (let index = 0; index < this.texto3Sub?.length; index++) {
        this.animatedText3Sub += this.texto3Sub.charAt(index);
        await this.delay(15);
      }
    },

    async textSpeak4() {
      this.animatedText4 = "";
      this.animatedText4Sub = "";
      for (let index = 0; index < this.texto4?.length; index++) {
        this.animatedText4 += this.texto4.charAt(index);
        await this.delay(20);
      }

      for (let index = 0; index < this.texto4Sub?.length; index++) {
        this.animatedText4Sub += this.texto4Sub.charAt(index);
        await this.delay(15);
      }
    },

    async textSpeak5() {
      this.animatedText5 = "";
      for (let index = 0; index < this.texto5?.length; index++) {
        this.animatedText5 += this.texto5.charAt(index);
        await this.delay(20);
      }
    },
    async textSpeak6() {
      this.animatedText6 = "";
      for (let index = 0; index < this.texto6?.length; index++) {
        this.animatedText6 += this.texto6.charAt(index);
        await this.delay(20);
      }
    },
    async textSpeak7() {
      this.animatedText7 = "";
      for (let index = 0; index < this.texto7?.length; index++) {
        this.animatedText7 += this.texto7.charAt(index);
        await this.delay(20);
      }
    },
    async textSpeak9() {
      this.animatedText9 = "";
      for (let index = 0; index < this.texto9?.length; index++) {
        this.animatedText9 += this.texto9.charAt(index);
        await this.delay(20);
      }
    },

    async textSpeak10() {
      this.animatedText10 = "";
      this.animatedText10Sub = "";
      this.animatedText10Sub2 = "";
      for (let index = 0; index < this.texto10?.length; index++) {
        this.animatedText10 += this.texto10.charAt(index);
        await this.delay(20);
      }
      for (let index = 0; index < this.texto10Sub?.length; index++) {
        this.animatedText10Sub += this.texto10Sub.charAt(index);
        await this.delay(15);
      }

      for (let index = 0; index < this.texto10Sub2?.length; index++) {
        this.animatedText10Sub2 += this.texto10Sub2.charAt(index);
        await this.delay(15);
      }
    },
    async textSpeak11() {
      this.animatedText11 = "";
      this.animatedText11Sub = "";
      for (let index = 0; index < this.texto11?.length; index++) {
        this.animatedText11 += this.texto11.charAt(index);
        await this.delay(20);
      }
      for (let index = 0; index < this.texto11Sub?.length; index++) {
        this.animatedText11Sub += this.texto11Sub.charAt(index);
        await this.delay(15);
      }
    },
    async textSpeak12() {
      this.animatedText12 = "";
      for (let index = 0; index < this.texto12?.length; index++) {
        this.animatedText12 += this.texto12.charAt(index);
        await this.delay(20);
      }
    },
    async textSpeak13() {
      this.animatedText13 = "";
      this.animatedText13Sub = "";
      this.animatedText13Sub2 = "";
      for (let index = 0; index < this.texto13?.length; index++) {
        this.animatedText13 += this.texto13.charAt(index);
        await this.delay(20);
      }
      for (let index = 0; index < this.texto13Sub?.length; index++) {
        this.animatedText13Sub += this.texto13Sub.charAt(index);
        await this.delay(15);
      }

      for (let index = 0; index < this.texto13Sub2?.length; index++) {
        this.animatedText13Sub2 += this.texto13Sub2.charAt(index);
        await this.delay(15);
      }
    },
    async textSpeak14() {
      this.animatedText14 = "";
      this.animatedText14Sub = "";
      for (let index = 0; index < this.texto14?.length; index++) {
        this.animatedText14 += this.texto14.charAt(index);
        await this.delay(20);
      }
      for (let index = 0; index < this.texto14Sub?.length; index++) {
        this.animatedText14Sub += this.texto14Sub.charAt(index);
        await this.delay(15);
      }
    },
    async textSpeak15() {
      this.animatedText15 = "";
      this.animatedText15Sub = "";
      for (let index = 0; index < this.texto15?.length; index++) {
        this.animatedText15 += this.texto15.charAt(index);
        await this.delay(20);
      }
      for (let index = 0; index < this.texto15Sub?.length; index++) {
        this.animatedText15Sub += this.texto15Sub.charAt(index);
        await this.delay(15);
      }
    },
    async textSpeak17() {
      this.animatedText17 = "";
      this.animatedText17Sub = "";
      for (let index = 0; index < this.texto17?.length; index++) {
        this.animatedText17 += this.texto17.charAt(index);
        await this.delay(20);
      }
      for (let index = 0; index < this.texto17Sub?.length; index++) {
        this.animatedText17Sub += this.texto17Sub.charAt(index);
        await this.delay(15);
      }
    },

    async typeText(step) {
      if (step == 0) {
        this.texto0 = "Olá, bem vindo(a) a cotação da proteção";
        this.texto0Sub = "Bike Registrada!";
        await this.textSpeak();
      } else if (step == 1) {
        this.texto1 = "Como podemos te chamar?";
        await this.textSpeak1();
      } else if (step == 2) {
        this.texto2 = "qual é a sua data de nascimento?";
        await this.textSpeak2();
      } else if (step == 3) {
        this.texto3 = " você tem Celular?";
        this.texto3Sub =
          "Vamos verificar se existem promoções ativas em sua região.";
        await this.textSpeak3();
      } else if (step == 4) {
        this.texto4 = " qual é o seu melhor email?";
        this.texto4Sub =
          "Não se preocupe, não iremos enviar SPAM, é apenas para identificarmos você em nossa base.";
        this.textSpeak4();
      } else if (step == 5) {
        this.texto5 =
          "vamos precisar saber algumas informações sobre a sua bike para cotar a sua proteção.";
        await this.textSpeak5();
      } else if (step == 6) {
        this.texto6 =
          "vamos revisar todos os dados digitados para garantir que está recebendo a melhor cotação!";
        await this.textSpeak6();
      } else if (step == 7) {
        this.texto7 = " você gostaria de extender a proteção para seu GPS?";
        await this.textSpeak7();
      } else if (step == 9) {
        this.texto9 =
          " achei 3 planos para a sua bike! Qual deles melhor se encaixa para você?";
        await this.textSpeak9();
      } else if (step == 10) {
        this.texto10 = "temos uma novidade!";
        this.texto10Sub =
          "Chegou o seguro de acidentes pessoais para ciclistas!";
        this.texto10Sub2 =
          "Agora você fica coberto também. E o melhor, tudo na mesma assinatura e sem burocracia!";
        await this.textSpeak10();
      } else if (step == 11) {
        this.texto11 = " estamos quase lá!";
        this.texto11Sub =
          "Precisaremos de dados complementares para finalizar a sua proteção!";
        await this.textSpeak11();
      } else if (step == 12) {
        this.texto12 =
          " estamos quase lá! Informe o endereço onde reside sua bike!";
        await this.textSpeak12();
      } else if (step == 13) {
        this.texto13 = "Agora só faltam os dados de pagamento.";
        this.texto13Sub =
          "Após clicar em finalizar, validaremos o seu cartão de crédito.";
        this.texto13Sub2 =
          "Garanta sua proteção agora! Seu pagamento será debitado e, após a aprovação da sua bike, sua apólice estará pronta  no aplicativo Bike Registrada.";
        await this.textSpeak13();
      } else if (step == 14) {
        this.texto14 =
          "para o seu modelo de bike, temos uma cotação especial personalizada feita somente para você!";
        this.texto14Sub2 =
          "Clique no botão abaixo para seguir com a sua cotação!";
        await this.textSpeak14();
      } else if (step == 15) {
        this.texto15 = " sua bike possui nota fiscal?";
        this.texto15Sub2 =
          "Não se preocupe, caso não possua a nota fiscal, a mesma não é obrigatória para contratação.";
        await this.textSpeak15();
      } else if (step == 17) {
        this.texto17 = " agora nos informe o seu cpf?";
        this.texto17Sub = "Por favor, certifique-se de que o CPF informado seja o seu próprio e corresponda ao nome do titular do seguro.";
        await this.textSpeak17();
      }
      this.btn = false;
    },
    voltarPassoNF() {
      this.notaFiscal = true;
      let stepdefult = 16;
      //para aplicar o efeito
      this.step = 22;
      this.btn = true;
      setTimeout(() => {
        this.originalText = "";
        this.originalText2 = "";
        this.originalText3 = "";
        this.animatedText2 = "";
        this.animatedText = "";
        this.animatedText3 = "";
        this.step = stepdefult;
        this.step--;
        this.typeText(this.step);
      }, 800);
    },
    voltarPassoCPF() {
      this.notaFiscal = true;
      let stepdefult = 4;
      //para aplicar o efeito
      this.step = 22;
      this.btn = true;
      setTimeout(() => {
        this.originalText = "";
        this.originalText2 = "";
        this.originalText3 = "";
        this.animatedText2 = "";
        this.animatedText = "";
        this.animatedText3 = "";
        this.step = stepdefult;
        this.typeText(this.step);
      }, 800);
    },

    voltarPassoStep5() {
      this.notaFiscal = true;
      let stepdefult = 17;
      //para aplicar o efeito
      this.step = 22;
      this.btn = true;
      setTimeout(() => {
        this.step = stepdefult;
        this.typeText(this.step);
      }, 800);
    },
    async proximoPassoNF(value) {
      this.btn = true;
      if (value === 1) {
        this.notaFiscal = true;
        let stepdefult = 5;
        //para aplicar o efeito
        this.step = 22;
        setTimeout(() => {
          this.originalText = "";
          this.originalText2 = "";
          this.originalText3 = "";
          this.animatedText2 = "";
          this.animatedText = "";
          this.animatedText3 = "";
          this.step = stepdefult;
          this.step++;
          this.typeText(this.step);
        }, 800);
      } else if (value === 2) {
        this.notaFiscal = false;
        let stepdefult = 5;
        //para aplicar o efeito
        this.step = 22;
        setTimeout(() => {
          this.originalText = "";
          this.originalText2 = "";
          this.originalText3 = "";
          this.animatedText2 = "";
          this.animatedText = "";
          this.animatedText3 = "";
          this.step = stepdefult;
          this.step++;
          this.typeText(this.step);
        }, 800);
      } else {
        this.notaFiscal = false;
        let stepdefult = 6;
        //para aplicar o efeito
        this.step = 22;
        this.btn = true;
        setTimeout(() => {
          this.originalText = "";
          this.originalText2 = "";
          this.originalText3 = "";
          this.animatedText2 = "";
          this.animatedText = "";
          this.animatedText3 = "";
          this.step = stepdefult;
          this.step--;
          this.typeText(this.step);
        }, 800);
      }
    },
    selecionouPlano() {
      if (this.idTipoAssinatura == config.ID_TIPO_ASSINATURA_ANUAL) return this.proximoPasso();

      if (this.idTipoAssinatura == config.ID_TIPO_ASSINATURA_MENSAL) return this.showMelhorOferta = true;
    },
    async proximoPasso() {
      this.btn = true;
      let stepdefult = this.step;
      //para aplicar o efeito
      this.step = 22;
      setTimeout(() => {
        this.step = stepdefult;
        this.step++;
        if (this.step != 8) {
          this.originalText = "";
          this.originalText2 = "";
          this.originalText3 = "";
          this.animatedText2 = "";
          this.animatedText = "";
          this.animatedText3 = "";
          this.typeText(this.step);
        }
      }, 800);
    },
    async atualizarDadosBike() {
      try {
        var itemModalidade = this.lstModalidade.find(
          (item) => item.id == this.modalidade
        );
        this.loadingFinalizar = true;
        var data = {
          id: this.idSeguro,
          email: this.email,
          idMarcaBike: this.marcaBike.id,
          modalidadeBike: itemModalidade && itemModalidade.nome,
          idModalidadeBike: itemModalidade && itemModalidade.id,
          anoBike: this.ano,
          nascimento: this.nascimento
            ? moment(this.nascimento, "DD/MM/YYYY").format("YYYY-MM-DD")
            : undefined,
          cpf: this.cpf,
          rg: this.rg,
          telefoneInspecao: this.telefone,
        };
        await axios.put(`${config.API_URLV2}/seguro/dadosBikePessoais`, data);
        this.loadingFinalizar = false;
      } catch (error) {
        this.validacoes(error);
      }
    },
    async voltarPasso() {
      let stepdefult = this.step;

      //para aplicar o efeito
      this.step = 22;
      if (stepdefult == 9 && !this.coberturaAcessorio) {
        setTimeout(() => {
          this.step = 7;
          this.originalText = "";
          this.originalText2 = "";
          this.originalText3 = "";
          this.animatedText2 = "";
          this.animatedText = "";
          this.animatedText3 = "";
          this.typeText(this.step);
        }, 800);
      } else {
        setTimeout(() => {
          this.step = stepdefult;
          this.step--;
          this.originalText = "";
          this.originalText2 = "";
          this.originalText3 = "";
          this.animatedText2 = "";
          this.animatedText = "";
          this.animatedText3 = "";
          this.typeText(this.step);
        }, 800);
      }
    },
    async atualizarPessoais() {
      try {
        this.loading = true;
        this.aguarde = true;
        var data = {
          id: this.idSeguro,
          email: this.email,
          nascimento: this.nascimento
            ? moment(this.nascimento, "DD/MM/YYYY").format("YYYY-MM-DD")
            : undefined,
          cpf: this.cpf,
          rg: this.rg,
          telefoneInspecao: this.telefone,
        };
        await axios.put(`${config.API_URLV2}/seguro/dadosPessoais`, data);
        this.loading = false;
        this.aguarde = false;
      } catch (error) {
        this.validacoes(error);
      }
    },
    abrirModalCotacao() {
      this.abrirModal = true;
    },
    hideModal() {
      this.abrirModal = false;
    },
    abrirModalCotacaoFranquia() {
      this.abrirModalFranquia = true;
    },
    hideModalFranquia() {
      this.abrirModalFranquia = false;
    },
    async atualizarEndereco() {
      try {
        this.loading = true;
        this.aguarde = true;
        var data = {
          id: this.idSeguro,
          email: this.email,
          numeroEndereco: this.numEndereco,
          bairroEndereco: this.bairro,
          complementoEndereco: this.complemento,
          cep: this.cep,
          cidade: this.cidade,
          uf: this.estado,
          endereco: this.logradouro,
        };
        await axios.put(`${config.API_URLV2}/seguro/dadosEndereco`, data);
        this.loadingFinalizar = false;
        this.cepBrinde = this.cep;
        this.logradouroBrinde = this.logradouro;
        this.cidadeBrinde = this.cidade;
        this.bairroBrinde = this.bairro;
        this.estadoBrinde = this.estado;
        this.numEnderecoBrinde = this.numEndereco;
        this.loading = false;
        this.aguarde = false;
      } catch (error) {
        this.validacoes(error);
      }
    },

    async criarNovoSeguro(value) {
      try {
        this.btn = true;
        this.loading = true;
        this.aguarde = true;
        var urlOrigem = document.referrer;
        var urlPagina = window.location.href;

        this.valorTotalSeguro = this.valorPlano;
        if (!value) {
          this.separarDecimalTotal(this.valorTotalSeguro);
          this.escolherOpicional(null, null);
        } else {
          this.valorTotalSeguro += this.valorPlanoOpcionalEscolhido;
        }

        var data = {
          plano: this.plano,
          valorPlano: this.valorPlano,
          planoOpcional: this.planoOpcionalEscolhido,
          valorPlanoOpcional: this.valorPlanoOpcionalEscolhido,
          IdTipoAssinatura: this.idTipoAssinatura,
          email: this.email,
          urlOrigem: urlOrigem,
          urlPagina: urlPagina,
          valorPlanoAcessorio: this.valorPlanoAcessorio,
        };

        if (this.idTipoAssinatura != config.ID_TIPO_ASSINATURA_MENSAL) {
          await this.buscarParcelas();
        }

        let response = await axios.post(`${config.API_URLV2}/seguro`, data);
        this.loading = false;
        this.aguarde = false;
        this.idSeguro = response.data.id;
        var dadosSimulacao = {
          id: response.data.id,
          email: this.email,
          telefone: this.telefone,
          nome: this.nome,
          valorBike: this.valorBike,
          modeloBike: this.modeloBike,
          marcaBike: this.marcaBike,
          plano: this.plano,
          planoOpcional: this.planoOpcionalEscolhido,
          valorPlanoOpcional: this.valorPlanoOpcionalEscolhido,
          valorPlano: this.valorPlano,
          competicoesInternacionais: this.competicoesInternacionais,
          passoCheckout: true,
          tipoAssinatura: this.idTipoAssinatura,
          coberturaAcessorio: this.coberturaAcessorio,
          nomeAcessorio: this.nomeAcessorio,
          marcaAcessorio: this.marcaAcessorio,
          valorAcessorio: this.valorAcessorio > 0 ? this.valorAcessorio : null,
          valorPlanoAcessorio: this.valorPlanoAcessorio,
        };

        var strValue = JSON.stringify(dadosSimulacao);
        window.sessionStorage.setItem("dadosSimulacao", strValue);

        this.proximoPasso();
      } catch (error) {
        return this.validacoes(error);
      }
    },
    ObterUrlParceiro() {
      if (this.$route.params.urlParceiro) {
        this.urlParceiro = this.$route.params.urlParceiro;
        var strValue = JSON.stringify(this.urlParceiro);
        sessionStorage.setItem("urlParceiro", strValue);
      }
      if (sessionStorage.getItem("urlParceiro")) {
        var result = sessionStorage.getItem("urlParceiro");
        this.urlParceiro = JSON.parse(result);
      }
    },
    popularInformacoes() {
      this.ObterUrlParceiro();
    },
    async atualizarNovoSeguroLead() {
      try {
        this.loading = true;
        this.aguarde = true;
        var data = {
          modelo: this.modeloBike,
          valor: this.valorBike,
          marca: this.marcaBike.nome,
          email: this.email,
          cpf: this.cpf,
          nome: this.nome,
          notaFiscal: this.notaFiscal,
          telefone: this.telefone,
          competicoesInter: this.competicoesInternacionais,
          cnpj: this.cnpjLoja,
          ufCliente: this.ufCotacao,
          urlParceiro: this.urlParceiro,
          marcaAcessorio: this.marcaAcessorio,
          nomeAcessorio: this.nomeAcessorio,
          valorAcessorio: this.valorAcessorio > 0 ? this.valorAcessorio : null,
        };
        await axios.put(
          `${config.API_URLV2}/seguro/atualizarNovoSeguroLead`,
          data
        );
        this.loading = false;
        this.aguarde = false;
      } catch (error) {
        this.validacoes(error);
      }
    },
    validacoes(error) {
      this.btn = false;
      this.loading = false;
      this.aguarde = false;

      if (error?.response?.data?.error) {
        this.$toast.error(error?.response?.data?.error, {
          duration: 8000,
          position: "top",
        });
      } else if (error?.response?.data?.emailInvalido) {
        this.step = 4;
        Swal.fire({
          title: "Atenção",
          html: `<p> Por algum motivo não conseguimos verificar a autenticidade do e-mail informado.</p><p> Por favor informe o seu melhor e-mail e tente novamente ou entre em contato com o nosso suporte.</p>`,
          showCancelButton: true,
          confirmButtonColor: "#28a745",
          cancelButtonText: "Fechar",
          confirmButtonText: "Entrar em contato com o suporte",
        }).then((result) => {
          if (result.value) {
            window.open(
              "https://api.whatsapp.com/send?phone=556135224521&text=Olá, estou no site e preciso de ajuda para validar meu e-mail.",
              "_blank"
            );
          }
        });
      } else {
        Swal.fire({
          title: "Não foi possível assinar seu seguro",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }

      if (!error?.response?.data?.emailInvalido) {
        this.voltarPasso();
      }
      return false;
    },
    cardSelecionadoUrbano(plano) {
      this.planoSelecionado = plano;
      this.nivelPlano = "BAIXO";
      this.selecionadoPerformance = false;
      this.selecionadoAtivo = false;
      this.selecionadoUrbano = true;
      this.validarValoresTipoPlano(plano);
    },
    cardSelecionadoPrata(plano) {
      this.planoSelecionadoOpcional = plano;
      this.selecionadoOuro = false;
      this.selecionadoPrata = true;
      this.validarValoresTipoPlanoOpicional(plano);
    },
    cardSelecionadoOuro(plano) {
      this.planoSelecionadoOpcional = plano;
      this.selecionadoOuro = true;
      this.selecionadoPrata = false;
      this.validarValoresTipoPlanoOpicional(plano);
    },

    cardSelecionadoAtivo(plano) {
      this.planoSelecionado = plano;
      this.nivelPlano = "MÉDIO";
      this.selecionadoPerformance = false;
      this.selecionadoAtivo = true;
      this.selecionadoUrbano = false;
      this.validarValoresTipoPlano(plano);
    },
    cardSelecionadoPerformance(plano) {
      this.planoSelecionado = plano;
      this.nivelPlano = "ALTO";
      this.selecionadoPerformance = true;
      this.selecionadoAtivo = false;
      this.selecionadoUrbano = false;
      this.validarValoresTipoPlano(plano);
    },
    validarValoresTipoPlano(plano) {
      if (this.idTipoAssinatura == config.ID_TIPO_ASSINATURA_ANUAL) {
        this.valorMkt = plano.valorAnualComAssistencia24hMKT;
        this.escolherPlano(
          plano.id,
          plano.valorAnualComAssistencia24h,
          plano.valorAcessorioAnual
        );
      } else if (this.idTipoAssinatura == config.ID_TIPO_ASSINATURA_SEMESTRAL) {
        this.valorMkt = plano.valorSemestralComAssistencia24hMKT;
        this.escolherPlano(
          plano.id,
          plano.valorSemestralComAssistencia24h,
          plano.valorAcessorioSemestral
        );
      } else {
        this.valorMkt = plano.valorMensalComAssistencia24hMKT;
        this.escolherPlano(
          plano.id,
          plano.valorMensalComAssistencia24h,
          plano.valorAcessorioMensal
        );
      }
    },

    validarValoresTipoPlanoOpicional(plano) {
      if (this.idTipoAssinatura == config.ID_TIPO_ASSINATURA_ANUAL) {
        this.escolherOpicional(plano.id, plano.valorAnualComAssistencia24h);
      } else if (this.idTipoAssinatura == config.ID_TIPO_ASSINATURA_SEMESTRAL) {
        this.escolherOpicional(plano.id, plano.valorSemestralComAssistencia24h);
      } else {
        this.escolherOpicional(plano.id, plano.valorMensalComAssistencia24h);
      }
    },

    escolherPlano(plano, valorPlano, valorPlanoAcessorio) {
      this.plano = plano;
      this.valorPlano = valorPlano;
      this.seguroAp = true;
      this.valorPlanoAcessorio = valorPlanoAcessorio;
    },
    escolherOpicional(plano, valorPlano) {
      if (!plano) {
        this.selecionadoOuro = false;
        this.selecionadoPrata = false;
        this.planoSelecionadoOpcional = null;
      }
      this.planoOpcionalEscolhido = plano;
      this.valorPlanoOpcionalEscolhido = valorPlano;

      if (this.separarDecimalOpicional(valorPlano));
    },

    btnPlanoAnual() {
      this.planoAnual = true;
      this.planoSemestral = false;
      this.planoMensal = false;
      this.idTipoAssinatura = config.ID_TIPO_ASSINATURA_ANUAL;
      this.validarValoresTipoPlano(this.planoSelecionado);
      return;
    },
    btnPlanoSemestral() {
      this.planoSemestral = true;
      this.planoAnual = false;
      this.planoMensal = false;
      this.idTipoAssinatura = config.ID_TIPO_ASSINATURA_SEMESTRAL;
      this.validarValoresTipoPlano(this.planoSelecionado);
      return;
    },

    btnPlanoMensal() {
      this.planoAnual = false;
      this.planoMensal = true;
      this.planoSemestral = false;
      this.idTipoAssinatura = config.ID_TIPO_ASSINATURA_MENSAL;
      this.validarValoresTipoPlano(this.planoSelecionado);
      return;
    },

    habilitarAcessorio(value) {
      this.btn = true;
      this.coberturaAcessorio = value;
      if (!value) {
        (this.coberturaAcessorio = false),
          (this.marcaAcessorio = undefined),
          (this.nomeAcessorio = undefined),
          (this.valorAcessorio = 0),
          (this.valorPlanoAcessorio = undefined),
          this.proximoPasso();
      }
      setTimeout(() => {
        this.btn = false;
        window.scrollBy({
          top: window.innerHeight, // Rola para baixo uma janela completa
          behavior: "smooth",
        }); // Ajuste o valor conforme necessário
      }, 0);
    },
    proximoPassoDuplo() {
      this.btn = true;
      this.step = this.step + 1;
      this.coberturaAcessorio = false;
      this.marcaAcessorio = undefined;
      this.nomeAcessorio = undefined;
      this.valorAcessorioString = "";
      this.valorAcessorio = 0;
      this.valorPlanoAcessorio = undefined;
      this.proximoPasso();
    },
    async salvarLead() {
      try {
        var strDadosLocalStorage = sessionStorage.getItem("idSeguro");
        if (strDadosLocalStorage) {
          this.idSeguro = JSON.parse(strDadosLocalStorage) || undefined;
        }

        this.loading = true;
        this.aguarde = true;
        var data = {
          nome: this.nome,
          telefone: this.telefone,
          email: this.email,
          codLinkIndicacao: this.codLinkIndicacao,
          idSeguro: this.idSeguro,
          ufCliente: this.ufCliente,
          nascimento: this.nascimento
            ? moment(this.nascimento, "DD/MM/YYYY").format("YYYY-MM-DD")
            : undefined,
        };

        var response = await axios.post(
          `${config.API_URLV2}/seguro/salvarLead`,
          data
        );
        this.idSeguro = response.data.idSeguro;

        var strValue = JSON.stringify(response.data.idSeguro);
        window.sessionStorage.setItem("idSeguro", strValue);

        this.loading = false;
        this.aguarde = false;
        return true;
      } catch (error) {
        this.validacoes(error);
      }
    },
    async verificarPodeContinuar() {
      try {
        var response = await axios.get(
          `${config.API_URLV2}/cliente/verificarExisteClienteComCpfEmail?email=${this.email}&cpf=${this.cpf}`
        );
        if (response.data.podeContinuar) {
          this.loading = false;
          return true;
        } else {
          this.loading = false;
          if (response.data.cliente) {
            Swal.fire({
              title: "Aviso!",
              icon: "info",
              html: `Olá, você está tentando usar um e-mail que já está vinculado a outro CPF: </b>  ${response.data.cliente}`,
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: false,
              confirmButtonText: '<i class="fa fa-thumbs-up"></i> Entendi!',
              confirmButtonAriaLabel: "Eu entendi!",
            });
          } else {
            Swal.fire({
              title: "Aviso!",
              icon: "info",
              html: `O CPF informado já está vinculado a uma conta</b>`,
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: false,
              confirmButtonText: '<i class="fa fa-thumbs-up"></i> Entendi!',
              confirmButtonAriaLabel: "Eu entendi!",
            });
          }
          return false;
        }
      } catch (error) {
        this.loadingFinalizar = false;
        Swal.fire({
          title: "Não foi possivel prosseguir com o seguro",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    },
    async buscarPlanos() {
      try {
        this.loading = false;
        this.aguarde = true;
        let data = {
          idSeguro: this.idSeguro,
          valorBike: this.valorBike,
          valorAcessorio: this.valorAcessorio,
          ufCliente: this.ufCotacao,
          nascimento: this.nascimento
            ? moment(this.nascimento, "DD/MM/YYYY").format("YYYY-MM-DD")
            : undefined,
        };

        var response = await axios.post(
          `${config.API_URLV2}/seguro/precosPlano`,
          data
        );

        this.lstPlanos =
          response.data.filter((item) => {
            if (
              item.id == config.ID_PLANO_OURO ||
              item.id == config.ID_PLANO_PRATA
            )
              return false;
            else return true;
          }) || [];
        let planoResponse = this.lstPlanos.filter(
          (w) => w.id === config.ID_PLANO_PERFORMANCE
        );
        //Deixar pre selecionado os vvalores do plano ativo
        this.cardSelecionadoPerformance(planoResponse[0]);

        this.lstPlanosOpcional =
          response.data.filter((item) => {
            if (
              item.id == config.ID_PLANO_OURO ||
              item.id == config.ID_PLANO_PRATA
            )
              return true;
            else return false;
          }) || [];

        let planoAcessorioResponse = this.lstPlanosOpcional.filter(
          (w) => w.id === config.ID_PLANO_OURO
        );
        //Deixar pre selecionado os vvalores do plano ativo
        this.cardSelecionadoOuro(planoAcessorioResponse[0]);

        this.loading = false;
        this.aguarde = false;
        return true;
      } catch (error) {
        this.loading = false;
        Swal.fire({
          title: "Não foi possível buscar os planos de seguro",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
        this.validacoes(error);

        return false;
      }
    },

    async buscarMarcas() {
      try {
        this.loadingMarca = true;
        var response = await axios.get(`${config.API_URLV2}/marca/marcas`);

        this.lstMarcas = response.data || [];
        this.loadingMarca = false;
      } catch (error) {
        location.reload(true);
        this.loadingMarca = false;
        Swal.fire({
          title: "Não foi possível buscar as marcas",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    },
    async buscarModalidades() {
      try {
        this.loadingModalidade = true;

        var response = await axios.get(
          `${config.API_URLV2}/tipobike/modalidades`
        );
        this.lstModalidade = response.data;

        this.loadingModalidade = false;
      } catch (error) {
        Swal.fire({
          title: "Não foi possível obter as modalidades",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
        this.validacoes(error);
      }
    },
    async finalizarSeguro() {
      try {
        var tokenCartaoIugu = await this.gerarHashCartao();
        var tokenCartaoAssinaturaIugu = await this.gerarHashCartao();

        var data = {
          id: this.idSeguro,
          email: this.email,
          tokenCartao: tokenCartaoIugu,
          tokenCartaoAssinatura: tokenCartaoAssinaturaIugu,
          cupom: this.cupom,
          parcela: this.parcela,
          contaB: this.contaB,
        };

        await axios.put(`${config.API_URLV2}/seguro/finalizar`, data);
        this.aguarde = false;
        sessionStorage.removeItem("idSeguro");
        this.$router.replace("/obrigado");
      } catch (error) {
        this.loading = false;
        this.aguarde = false;
        this.mensagemErrorFinalizarSeguro(error);
      }
    },
    mensagemErrorFinalizarSeguro(error) {
      var errorMessage = error?.response?.data?.message;
      if (errorMessage?.startsWith("Unexpected")) {
        this.loadingFinalizar = false;
        Swal.fire({
          title: "Não foi possível finalizar o seu seguro",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      } else if (error?.response?.data?.error) {
        this.$toast.error(error?.response?.data?.error, {
          duration: 8000,
          position: "top",
        });
      } else if (errorMessage) {
        Swal.fire({
          title: "Atenção",
          html: `
  <div style="text-align: left;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;">
<p>Não conseguimos validar o seu cartão. Por favor verifique os dados informados e tente novamente.</p>
<p>Abaixo os principias motivos:</p>
1 - Seu cartão está bloqueado para compras On-line.<br>
2 - Seu cartão está vencido.<br>
3 - Seu cartão está desabilitado para compras.<br>
4 - Algum dado do cartão foi digitado errado.<br>
<p class="mt-3">Caso persista, por favor entre em contato com a operadora do seu cartão e tente novamente.</p></div>`,
          icon: "warning",
          confirmButtonText: "Ok",
        });
      } else if (
        errorMessage ||
        (error &&
          error
            .toString()
            .toLowerCase()
            .indexOf("cartão") !== -1)
      ) {
        this.loadingFinalizar = false;
        Swal.fire({
          title: "Atenção",
          text: errorMessage || error.toString(),
          icon: "warning",
          confirmButtonText: "Ok",
        });
      } else {
        this.loadingFinalizar = false;

        Swal.fire({
          title: "Não foi possível finalizar o seu seguro",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    },
    voltarPassoEspecial() {
      let stepdefult = 5;
      //para aplicar o efeito
      this.step = 22;
      setTimeout(() => {
        this.step = stepdefult;
        this.originalText = "";
        this.originalText2 = "";
        this.originalText3 = "";
        this.animatedText2 = "";
        this.animatedText = "";
        this.animatedText3 = "";
        this.typeText(this.step);
      }, 800);
    },
    voltarPassoStep() {
      let stepdefult = 2;
      //para aplicar o efeito
      this.step = 22;
      setTimeout(() => {
        this.step = stepdefult;
        this.step--;
        this.originalText = "";
        this.originalText2 = "";
        this.originalText3 = "";
        this.animatedText2 = "";
        this.animatedText = "";
        this.animatedText3 = "";
        this.typeText(this.step);
      }, 800);
      if (this.step == 8 && !this.coberturaAcessorio) {
        setTimeout(() => {
          this.step = stepdefult;
          this.step++;
          this.originalText = "";
          this.originalText2 = "";
          this.originalText3 = "";
          this.animatedText2 = "";
          this.animatedText = "";
          this.animatedText3 = "";
          this.typeText(this.step);
        }, 800);
      }
    },
    async oterFetureFlagCupom() {
      this.esconderCupom = false;
      if (this.idTipoAssinatura === config.ID_TIPO_ASSINATURA_ANUAL) {
        try {
          var response = await axios.get(
            `${config.API_URLV2}/cupom/featureFlag`
          );
          this.esconderCupom = response.data;
        } catch {
          this.esconderCupom = false;
        }
      }
    },
    async onClickAplicarCupom(modificou) {
      this.limpar = false;
      let values = modificou.cupom;
      this.showModalCupom = false;
      this.cupom = values;

      //Busca apenas se tiver digitado algum cupom
      if (!this.cupom) {
        return;
      }
      try {
        this.loadingCupom = true;

        var response = await axios.get(
          `${config.API_URLV2}/cupom/BuscarCupom/${this.cupom}`
        );

        this.valorCupom = response.data.percentual;
        this.cupomAtivo = response.data.ativo;

        var response2 = await axios.post(
          `${config.API_URLV2}/seguro/ObterDesconto/${this.idSeguro}/${this.cupom}`
        );

        this.valorPlanoOpcionalEscolhido =
          response2.data.valorDescontoOpicional;

        this.valorPlanoEscolhido = response2.data.valorDescontoPlano;
        this.valorPlanoAcessorioEscolhido =
          response2.data.valorDescontoAcessorio;
        this.valorTotalSeguroEscolhido = response2.data.valorTotal;

        this.cupomAplicado = true;
        this.loadingCupom = false;
      } catch (error) {
        this.limpar = true;
        this.valorTotalSeguroEscolhido = 0;
        var strDadosLocalStorage = sessionStorage.getItem("dadosSimulacao");
        var dadosCotacao = JSON.parse(strDadosLocalStorage);
        this.valorPlanoEscolhido = dadosCotacao.valorPlano;
        this.valorPlanoOpcionalEscolhido = dadosCotacao.valorPlanoOpcional;
        this.cupomAplicado = false;
        this.loadingCupom = false;
        Swal.fire({
          title: "Cupom de desconto inválido!",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    },
    buscarEnderecoCep(cep) {
      //Busca apenas se tiver digitado o cep completo
      if (!cep || cep?.length < 9) {
        return;
      }

      this.loadingCep = true;

      axios
        .get(`${config.API_URLV2}/cliente/BuscarEndereco?cep=${cep}`)
        .then((response) => {
          this.loadingCep = false;
          this.exibirCamposEndereco = true;

          //Se não veio os dados
          if (!response.data || !response.data.logradouro) {
            Swal.fire(
              "Atenção!",
              "Não encontramos o CEP informado em nossa base. Por favor, preencha o endereço completo.",
              "warning"
            );
            return;
          }

          this.logradouro = response.data.logradouro;
          this.cidade = response.data.localidade;
          this.bairro = response.data.bairro;
          this.estado = response.data.uf;
        })
        .catch((response) => {
          this.loadingCep = false;
          this.exibirCamposEndereco = true;
          this.validacoes(response);
        });
    },
    abrirModalInfor() {
      this.titulo = "Informação sobre plano";
      this.showModalPlanos = true;
    },
    abrirModalCupom() {
      this.showModalCupom = true;
    },
    hideModalInfor() {
      this.showModalPlanos = false;
    },
    hideModalCupom() {
      this.showModalCupom = false;
    },
    obterValidadeCartao() {
      var mesCartao = 0;
      var anoCartao = 0;

      if (this.validadeCartao) {
        var partes = this.validadeCartao.split("/");
        mesCartao = partes[0];
        anoCartao = partes[1];
      }

      return [mesCartao, anoCartao];
    },
    gerarHashIuguAsync(dados) {
      return new Promise((resolve, reject) => {
        try {
          var cc = window["Iugu"].CreditCard(
            dados.number,
            dados.expirationMonth,
            dados.expirationYear,
            dados.firstName,
            dados.lastName,
            dados.cvc
          );

          var valido = cc.valid();

          if (valido == false) {
            reject("Cartão inválido. Por favor verifique os dados.");
          }

          window["Iugu"].createPaymentToken(cc, function (data) {
            if (data.errors) {
              reject(
                "Ocorreu um erro ao tentar processar o pagamento com seu cartão. Favor verificar os dados e confirmar se seu cartão está liberado para compras online."
              );
            } else {
              resolve(data.id);
            }
          });
        } catch (e) {
          reject("Erro ao criar token Iugu: " + JSON.stringify(e));
        }
      });
    },
    async gerarHashCartao() {
      var validadeCartaoArr = this.obterValidadeCartao();
      var hash = await this.gerarHashIuguAsync({
        number: this.numCartao.replace(/ /g, ""),
        expirationMonth: validadeCartaoArr[0],
        expirationYear: validadeCartaoArr[1],
        firstName: helper.quebrarNome(this.nomeCartao)[0],
        lastName: helper.quebrarNome(this.nomeCartao)[1],
        cvc: this.cvcCartao,
      });
      return hash;
    }
  },
};
function initCard() {
  new Card({
    // a selector or DOM element for the form where users will
    // be entering their information
    form: "#formPagamento", // *required*
    // a selector or DOM element for the container
    // where you want the card to appear
    container: ".card-wrapper", // *required*

    formSelectors: {
      numberInput: 'input[name="cardNumber"]', // optional — default input[name="number"]
      expiryInput: 'input[name="cardExpiry"]', // optional — default input[name="expiry"]
      cvcInput: 'input[name="cardCvc"]', // optional — default input[name="cvc"]
      nameInput: 'input[name="cardName"]', // optional - defaults input[name="name"]
    },

    //width: 200, // optional — default 350px
    formatting: true, // optional - default true

    // Strings for translation - optional
    messages: {
      validDate: "valid\ndate", // optional - default 'valid\nthru'
      monthYear: "mm/yy", // optional - default 'month/year'
    },

    // Default placeholders for rendered fields - optional
    placeholders: {
      name: "SEU NOME",
    },

    // if true, will log helpful messages for setting up Card
    debug: false, // optional - default false
  });
}
</script>
<style>
@import url("https://fonts.googleapis.com/css?family=Inter");

.btns {
  width: 167px;
  height: 46px;
  border-radius: 25px;
  border: 1px;
  background: #089f17;
  border: 1px solid #089f17;
  margin-top: 10px;
  padding-bottom: 3px;
}
</style>
<style scoped>
input:focus::placeholder {
  color: transparent;
}

.n-a {
  color: #9f9f9f;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.swal2-cancel {
  border-radius: 30px !important;
}

.gap-f {
  gap: 12px;
}

.gap-f2 {
  gap: 12px;
}

.texto-principal {
  color: #7c7c7c;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateX(10px);
  opacity: 0;
}

.texto-principal .sub {
  color: #7c7c7c;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.texto-principal .sub-2 {
  font-size: 16px;
  font-weight: 400;
  text-align: justify;
}

.texto-principal .sub-3 {
  text-align: left;
}

.padrao {
  color: #7c7c7c;
}

.padrao-2 {
  color: #7c7c7c;
  font-size: 18px;
  font-family: Inter;
}

.padrao-destaque {
  color: #089f17;
}

.section-bike-img {
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0;
}

.section-steps {
  align-items: center;
  justify-content: center;
  display: flex;
}

.bike-img {
  width: 137px;
  height: 36px;
}

.step1 {
  height: 100vh;
  display: flex;
  align-items: center;
  text-align: center;
}

.step2 {
  margin-left: 257px;
  margin-right: 251px;
}

.step8 {
  margin-left: 257px;
  margin-right: 251px;
  display: flex;
  justify-content: center;
}

.step5 {
  margin-left: 262px;
  margin-right: 297px;
  display: flex;
  justify-content: center;
}

.step9 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 65vh;
  /* Garante que a seção ocupe toda a altura da janela de visualização */
  margin: 0;
}

.btn-link-f {
  cursor: pointer;
  color: #797979;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
}

.step13 {
  margin-right: 142px;
  margin-left: 162px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.step3 {
  height: 70vh;
  display: flex;
  justify-content: center;
  /* Centralize horizontalmente */
  align-items: center;
}

/* passo a passo */
.stepper-wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 7px;
  left: -50%;
  z-index: 2;
}

.margin-desk {
  margin-right: 20px;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 7px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item.completed .step-counter {
  background-color: #23ad21;
  width: 25px;
  height: 25px;
  margin-top: -5px;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #23ad21;
  width: 100%;
  top: 7px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}

.stepper-item:last-child::after {
  content: none;
}

.form-control1 {
  display: block;
  width: 100%;
  height: 56px;
  border-radius: 17px;
  border: 1px solid #e9e9e9;
  background: #f5f5f5;
  color: #9a9a9a;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-left: 14px;
}

.form-control1:focus {
  /* Adiciona uma sombra suave quando o input é selecionado */
  outline: none;
  background: #fff;
}

.form-control1::placeholder {
  /* Adiciona uma sombra suave quando o input é selecionado */
  outline: none;
  color: #9a9a9a;
}

.font-principal {
  font-family: Inter;
}

.form-control2:focus {
  /* Adiciona uma sombra suave quando o input é selecionado */
  color: #454545;
  outline: none;
  background: #fff;
}

.form-control2::placeholder {
  /* Adiciona uma sombra suave quando o input é selecionado */
  outline: none;
  color: #9a9a9a;
}

.form-control3::placeholder {
  /* Adiciona uma sombra suave quando o input é selecionado */
  outline: none;
  color: #9a9a9a;
}

.form-control3:focus {
  /* Adiciona uma sombra suave quando o input é selecionado */
  color: #454545;
  outline: none;
  background: #fff;
}

.form-control-select {
  border-radius: 17px;
  border: 1px solid #e9e9e9;
  background: #fff;
  width: 100%;
  height: 56px;
  flex-shrink: 0;
  color: #646464;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.form-control-select:focus {
  /* Adiciona uma sombra suave quando o input é selecionado */
  color: #454545;
  outline: none;
  background: #fff;
}

.form-control-select-2 {
  padding-left: 14px;
  color: #646464;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  height: 56px;
  flex-shrink: 0;
  border-radius: 17px;
  border: 1px solid #e9e9e9;
  background: #fff;
}

.plano9 {
  padding-bottom: 0px;
}

.form-control-select-2:focus {
  /* Adiciona uma sombra suave quando o input é selecionado */
  color: #454545;
  outline: none;
  background: #fff;
}

.form-control3 {
  padding-left: 14px;
  width: 100%;
  height: 56px;
  flex-shrink: 0;
  color: #9a9a9a;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 17px;
  border: 1px solid #e9e9e9;
  background: #fafafa;
}

.invalid-feedback {
  font-size: 14px;
}

.bike-btn-s {
  width: 197px;
  height: 42px;
  border-radius: 25px;
  border: 1px solid #089f17;
  background: #089f17;
  color: #fff;
}

.bike-btn {
  width: 167px;
  height: 46px;
  border-radius: 25px;
  border: 1px;
  background: #089f17;
  border: 1px solid #089f17;
  margin-top: 10px;
  padding-bottom: 3px;
}

.bike-btn-f {
  width: 160px;
  height: 46px;
  border-radius: 6px;
  border: 1px solid #089f17;
  background: #089f17;
}

.texto-card-desktop {
  font-size: 13px;
}

.bike-btn-f .textt {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.bike-btn .text {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.link-bike {
  cursor: pointer;
  margin-bottom: 12px;
  color: #797979;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
}

.revisao-cards {
  height: 154px;
  flex-shrink: 0;
  border-radius: 17px;
  border: 1px solid #e9e9e9;
  background: #fff;
  padding: 17px;
}

.revisao-cards-2 {
  border-radius: 17px;
  border: 1px solid #e9e9e9;
  background: #fff;
  padding: 17px;
}

.link-card {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  color: #797979;
  text-decoration: underline;
}

.link-card-editar-dados {
  color: #797979;
  cursor: pointer;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
}

.btn-sim {
  color: #646464;
  width: 127px;
  height: 56px;
  font-weight: 700;
  border-radius: 17px;
  border: 1px;
  background: linear-gradient(0deg, #ffffff, #ffffff);
  border: 1px solid #089f17;
}

.btn-nao {
  color: #646464;
  font-weight: 700;
  width: 127px;
  height: 56px;
  border-radius: 17px;
  border: 1px;
  background: linear-gradient(0deg, #ffffff, #ffffff);
  border: 1px solid #ff0000;
}

.preco-numero {
  margin-top: 16px;
  font-size: 12px;
  line-height: 8px;
}

.img-button {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}

.btn-sim-1 {
  height: 56px;
  flex-shrink: 0;
  border-radius: 17px;
  border: 1px solid #089f17;
  background: #fff;
  color: #646464;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.btn-nao-2 {
  height: 56px;
  flex-shrink: 0;
  border-radius: 17px;
  border: 1px solid #f00;
  background: #fff;
  color: #646464;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.texto-plano-flutuante {
  color: #757575;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.texto-plano-flutuante-2 {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #089f17;
  margin-bottom: 3px;
  text-transform: uppercase;
}

.texto-plano-flutuante-3 {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #089f17;
  margin-bottom: 3px;
  text-transform: uppercase;
}

.texto-plano-flutuante-opicional-3 {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  text-align: right;
  color: #089f17;
  margin-bottom: 3px;
  text-transform: uppercase;
}

.texto-plano-flutuante-opicional-4 {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  text-align: right;
  margin-bottom: 3px;
  text-transform: uppercase;
}

.btn-font-desconto-text-active {
  background: #d9d9d9;
  border-radius: 4px;
  font-size: 9px;
  color: #808080;
  display: flex;
  justify-content: center;
}

.margin-flutuante {
  width: 140px;
  margin-top: 50px;
}

.margin-flutuanten {
  width: 150px;
  margin-top: 50px;
}

.margin-flutuantep {
  margin-top: 50px;
  width: 100px;
}

.margin-flutuante2 {
  width: 150px;
  margin-top: 50px;
}

.margin-flutuante-preco {
  width: 204px;
  margin-top: 40px;
  text-align: center;
  justify-content: center;
  margin-left: 20px;
}

.margin-flutuante-escolhido {
  width: 190px;
  margin-top: 50px;
}

.btn-font-desconto-active {
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 1px;
  text-align: center;
  color: #ffffff;
}

.retangulo-flutuante {
  background: #ffff;
  position: fixed;
  position: fixed;
  bottom: 0px;
  left: 0%;
  width: 100%;
  height: 130px;
  box-shadow: 0px 0px 5px 2px #00000026;
}

.retangulo-flutuante-organizacao {
  align-items: center;
  justify-content: center;
  display: flex;
}

.card-tipo {
  display: flex;
  gap: 2%;
}

.card-tipo2 {
  display: flex;
  gap: 2%;
  text-align: left;
}

.card-tipo-plano {
  width: 100px;
  height: 36px;
  border-radius: 6px;
  cursor: pointer;
  border: 1px;
  background: #f5f5f5;
  border: 1px solid #eeeeeeee;
}

.card-tipo-plano-ativo {
  width: 100px;
  cursor: pointer;
  height: 36px;
  border-radius: 9px;
  border: 1px;
  background: #089f17;
  border: 1px solid #eeeeeeee;
}

.tag {}

.tag-text {
  background: #d9d9d9;
  border-radius: 4px;
  font-size: 9px;
  color: #808080;
  display: flex;
  justify-content: center;
  margin-right: 11px;
  margin-left: 11px;
}

.tag-text2 {
  background: #d9d9d9;
  border-radius: 4px;
  font-size: 9px;
  color: #808080;
  display: flex;
  justify-content: center;
}

.tag-text-percentual {
  font-size: 10px;
}

.tag-text-percentual-f {
  font-size: 10px;
  margin-left: 20px;
  margin-right: 17px;
  font-weight: 700;
  color: #757575;
}

.card-plano {
  width: 266px;
  height: 256px;
  cursor: pointer;
  border-radius: 6px;
  background: #f1f1f1;
  border: 1px solid #f1f1f1;
}

.card-plano-opicional {
  width: 266px;
  height: 256px;
  cursor: pointer;
  border-radius: 6px;
  background: #f1f1f1;
  border: 1px solid #f1f1f1;
}

.card-plano-titulo {
  height: 46px;
  background: #cbcbcb;
  border-radius: 6px 6px 0px 0px;
  text-align: center;
}

.card-plano-titulo-texto {
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
  color: #757575;
}

.card-plano-sub-titulo {
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.card-plano-sub-titulo-opicional {
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 28px;
}

.card-plano-sub-titulo-nivel {
  color: #818181;
}

.card-plano-sub-titulo-nivel-2 {
  color: #089f17;
}

.card-plano-nivel {
  display: flex;
  gap: 2%;
  justify-content: center;
}

.card-plano-nivel-baixo {
  font-family: Inter;
  width: 60px;
  height: 15px;
  border-radius: 15px 0px 0px 15px;
  background: #6b6b6b;
  font-size: 10px;
  font-weight: 700;
  text-align: center;
  color: white;
}

.card-plano-nivel-baixo2 {
  background: #089f17;
  font-family: Inter;
  width: 60px;
  height: 15px;
  border-radius: 15px 0px 0px 15px;
  font-size: 10px;
  font-weight: 700;
  text-align: center;
  color: white;
}

.card-plano-nivel-medio {
  width: 60px;
  height: 15px;
  background: #d9d9d9;
  font-family: Inter;
  font-size: 10px;
  font-weight: 700;
  text-align: center;
}

.card-plano-nivel-medio2 {
  width: 60px;
  height: 15px;
  background: #089f17;
  font-family: Inter;
  font-size: 10px;
  font-weight: 700;
  text-align: center;
  color: white;
}

.card-plano-nivel-alto {
  font-family: Inter;
  width: 60px;
  height: 15px;
  background: #d9d9d9;
  border-radius: 0px 15px 15px 0px;
  font-size: 10px;
  font-weight: 700;
  text-align: center;
}

.card-plano-nivel-alto2 {
  font-family: Inter;
  color: white;
  width: 50px;
  height: 13px;
  background: #089f17;
  border-radius: 0px 15px 15px 0px;
  font-size: 8px;
  font-weight: 700;
  text-align: center;
}

.card-plano-coberturas {
  width: 192px;
  cursor: pointer;
  height: 19px;
  background: #e2e2e2;
  border-radius: 5px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}

.card-plano-valor {
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  margin-left: 67px;
  margin-top: 20px;
}

.card-plano-valor-de {
  text-decoration: line-through;
  color: #959595;
}

.card-plano-valor-por {
  color: #959595;
  font-size: 14px;
}

.card-plano-valor-total {
  font-family: Inter;
  font-size: 42px;
  font-weight: 700;
  text-align: left;
  color: #8b8b8b;
  margin-left: 102px;
  margin-top: -30px;
}

.card-plano-valor-total-opicional {
  font-family: Inter;
  font-size: 42px;
  font-weight: 700;
  text-align: center;
  color: #8b8b8b;
}

.card-plano-selecionar {
  cursor: pointer;
  font-weight: 700;
  height: 36px;
  border-radius: 0px 0px 6px 6px;
  background: #cbcbcb;
  font-size: 16px;
  color: #747474;
  text-align: center;
  margin-top: 15px;
  padding-top: 5px;
}

.card-body-plano-desktop {
  min-height: 394px;
  position: relative;
  background: #ffffff;
  border: 1px solid #a3a5a3;
  box-sizing: border-box;
  border-radius: 10px;
}

.card-body-plano-desktop-prata {
  min-height: 294px;
  position: relative;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: #ffffff;
  border: 1px solid #a3a5a3;
  box-sizing: border-box;
  border-radius: 10px;
}

.plano-recomendado-desktop {
  min-height: 447px;
}

.plano-recomendado-desktop-ouro {
  min-height: 379px;
}

.plano-nao-recomendado-desktop {
  margin-top: 20px;
}

.flutuante-de {
  color: #959595;
  text-align: left;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-decoration-line: line-through;
}

.texto-card-desktop {
  font-size: 13px;
}

.btn-block-desktop {
  border-radius: 5px;
  display: block;
  width: 100%;
  margin-bottom: -1px;
}

.modalidades {
  padding-left: 87px;
  padding-right: 87px;
}

.card-selecionado {
  width: 266px;
  cursor: pointer;
  height: 256px;
  border-radius: 6px;
  background: #ffff;
  border: 1px solid #089f17;
}

.card-selecionado-opicional {
  width: 266px;
  cursor: pointer;
  height: 266px;
  border-radius: 6px;
  background: #ffff;
  border: 1px solid #089f17;
}

.card-plano-titulo-selecionado {
  height: 46px;
  background: #089f17;
  border-radius: 4px 4px 0px 0px;
  text-align: center;
}

.card-plano-titulo-texto-selecionado {
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 48px;
  color: #f1f1f1;
}

.card-plano-coberturas-selecionado {
  width: 192px;
  cursor: pointer;
  height: 19px;
  background: #089f17;
  border-radius: 5px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  color: #f1f1f1;
  text-align: center;
}

.card-plano-valor-por-selecionado {
  color: #089f17;
  font-size: 14px;
}

.card-plano-valor-total-selecionado {
  font-family: Inter;
  font-size: 42px;
  font-weight: 700;
  text-align: left;
  color: #089f17;
  margin-left: 102px;
  margin-top: -30px;
}

.card-plano-valor-total-selecionado-opicional {
  font-family: Inter;
  font-size: 42px;
  font-weight: 700;
  text-align: center;
  color: #089f17;
}

.card-plano-selecionar-selecionado {
  cursor: pointer;
  font-weight: 700;
  height: 36px;
  border-radius: 0px 0px 4px 4px;
  color: #f1f1f1;
  font-size: 16px;
  background: #089f17;
  text-align: center;
  margin-top: 15px;
  padding-top: 5px;
}

.mobile {
  display: block;
}

.mobile2 {
  display: none;
}

.card-paid {
  padding: 15px;
  border-radius: 17px;
  margin-left: 0px;
  margin-right: 0px;
  border: 1px solid #e9e9e9;
}

.text-card-paid {
  padding-left: 20px;
  padding-right: 0px;
  font-size: 16px;
  padding-top: 0px;
}

.text-card-paid-font {
  font-size: 18px;
  text-align: left;
  /* Centraliza horizontalmente os elementos li */
}

.wap {
  width: 110px;
}

.padrao-destaque-revisao {
  color: #089f17;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.padrao-revisao {
  color: #646464;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.card-principal-planos-tipo {
  font-family: Inter;
  font-size: 10px;
  line-height: 14px;
}

.mobile-margin {
  padding-bottom: 175px;
}

@media (max-width: 991px) {
  .card-plano-valor-total-opicional {
    font-size: 50px;
  }

  .card-plano-valor-total-selecionado-opicional {
    font-size: 50px;
  }

  .margin-flutuante-preco {
    margin-top: 40px;
    width: 170px;
    text-align: center;
    justify-content: center;
    margin-left: 0px;
  }

  .text-card-paid-font {
    font-size: 16px;
  }

  .text-card-paid {
    padding-left: 30px;
    padding-right: 30px;
    font-size: 16px;
    padding-top: 11px;
  }

  .card-paid {
    margin-left: 2px;
    margin-right: 2px;
    padding: 5px;
  }

  .mobile2 {
    display: block;
  }

  .mobile {
    display: none;
  }

  .card-tipo2 {
    display: inline-block;
    text-align: center;
  }

  .card-plano {
    margin-bottom: 20px;
  }

  .card-plano-opicional {
    width: 306px;
    height: 292px;
    cursor: pointer;
    border-radius: 6px;
    margin-bottom: 24px;
    background: #f1f1f1;
    border: 1px solid #f1f1f1;
  }

  .card-tipo {
    justify-content: center;
  }

  .step2 {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 70px;
    display: block;
  }

  .step5 {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
  }

  .plano9 {
    padding-bottom: 172px;
  }

  .modalidades {
    padding-left: 0px;
    padding-right: 0px;
  }

  .margin-desk {
    margin-right: 0px;
  }

  .mobile-margin {
    padding-bottom: 175px;
  }

  .revisao-cards {
    font-size: 14px;
    padding: 10px;
  }

  .link-card {
    font-size: 14px;
    text-align: left;
  }

  .step8 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .step13 {
    margin-right: 0px;
    margin-left: 0px;
  }

  .card-paid {
    margin-left: 0px;
    margin-right: 0px;
    padding: 0px;
  }

  /* ================ card planos ================== */

  .card-plano {
    width: 306px;
    height: 290px;
    cursor: pointer;
    border-radius: 6px;
    background: #f1f1f1;
  }

  .card-plano-titulo {
    height: 56px;
    background: #cbcbcb;
    border-radius: 6px 6px 0px 0px;
    padding-top: 8px;
    text-align: center;
  }

  .card-plano-titulo-texto {
    font-family: Inter;
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    color: #757575;
  }

  .card-plano-sub-titulo {
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 5px;
  }

  .card-plano-sub-titulo-nivel {
    color: #818181;
  }

  .card-plano-sub-titulo-nivel-2 {
    color: #089f17;
  }

  .card-plano-nivel {
    display: flex;
    gap: 2%;
    justify-content: center;
  }

  .card-plano-nivel-baixo {
    font-family: Inter;
    width: 70px;
    height: 18px;
    border-radius: 15px 0px 0px 15px;
    background: #6b6b6b;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    color: white;
  }

  .card-plano-nivel-baixo2 {
    background: #089f17;
    font-family: Inter;
    width: 70px;
    height: 18px;
    border-radius: 15px 0px 0px 15px;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    color: white;
  }

  .card-plano-nivel-medio {
    width: 70px;
    height: 18px;
    background: #d9d9d9;
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
  }

  .card-plano-nivel-medio2 {
    width: 70px;
    height: 18px;
    background: #089f17;
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    color: white;
  }

  .card-plano-nivel-alto {
    font-family: Inter;
    width: 70px;
    height: 18px;
    background: #d9d9d9;
    border-radius: 0px 15px 15px 0px;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
  }

  .card-plano-nivel-alto2 {
    font-family: Inter;
    color: white;
    width: 70px;
    height: 18px;
    background: #089f17;
    border-radius: 0px 15px 15px 0px;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
  }

  .card-plano-coberturas {
    width: 224px;
    cursor: pointer;
    height: 23px;
    background: #e2e2e2;
    border-radius: 8px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
  }

  .card-plano-valor {
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    text-align: left;
    margin-left: 85px;
    margin-top: 10px;
  }

  .card-plano-valor-de {
    text-decoration: line-through;
    color: #959595;
  }

  .card-plano-valor-por {
    color: #959595;
    font-size: 16px;
  }

  .card-plano-valor-total {
    font-family: Inter;
    font-size: 50px;
    font-weight: 700;
    text-align: left;
    color: #8b8b8b;
    margin-left: 121px;
    margin-top: -32px;
  }

  .card-plano-selecionar {
    cursor: pointer;
    font-weight: 700;
    height: 45px;
    border-radius: 0px 0px 6px 6px;
    background: #cbcbcb;
    font-size: 24px;
    color: #747474;
    text-align: center;
    margin-top: 9px;
  }

  .card-body-plano-desktop {
    min-height: 394px;
    position: relative;
    background: #ffffff;
    border: 1px solid #a3a5a3;
    box-sizing: border-box;
    border-radius: 10px;
  }

  .card-body-plano-desktop-prata {
    min-height: 294px;
    position: relative;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    background: #ffffff;
    border: 1px solid #a3a5a3;
    box-sizing: border-box;
    border-radius: 10px;
  }

  .card-selecionado {
    width: 306px;
    cursor: pointer;
    height: 290px;
    border-radius: 6px;
    background: #ffff;
    border: 1px solid #089f17;
  }

  .card-selecionado-opicional {
    width: 306px;
    cursor: pointer;
    height: 292px;
    margin-bottom: 24px;
    border-radius: 6px;
    background: #ffff;
    border: 1px solid #089f17;
  }

  .card-plano-titulo-selecionado {
    height: 56px;
    background: #089f17;
    border-radius: 4px 4px 0px 0px;
    text-align: center;
    padding-top: 8px;
  }

  .card-plano-titulo-texto-selecionado {
    font-family: Inter;
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    color: #f1f1f1;
  }

  .card-plano-coberturas-selecionado {
    width: 224px;
    cursor: pointer;
    height: 23px;
    background: #089f17;
    border-radius: 8px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    color: #f1f1f1;
    text-align: center;
  }

  .card-plano-valor-por-selecionado {
    color: #089f17;
    font-size: 14px;
  }

  .card-plano-valor-total-selecionado {
    font-family: Inter;
    font-size: 50px;
    font-weight: 700;
    text-align: left;
    color: #089f17;
    margin-left: 121px;
    margin-top: -30px;
  }

  .card-plano-selecionar-selecionado {
    cursor: pointer;
    font-weight: 700;
    height: 45px;
    padding-top: 5px;
    border-radius: 0px 0px 6px 6px;
    color: #f1f1f1;
    font-size: 24px;
    background: #089f17;
    text-align: center;
    margin-top: 9px;
  }

  .bike-btn-f {
    border-radius: 27px;
    width: 100%;
    height: 40px;
  }

  .gap-f {
    gap: 20px;
  }

  .bike-btn-f .textt {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .img-button {
    width: 15px;
    height: 15px;
    margin-left: 3px;
    padding-top: -2px;
  }
}
</style>
